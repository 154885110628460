import React, { useState } from "react";

import TermsRequired from "./Authentication/TermsRequired";
import UserProfileRequired from "./Authentication/UserProfileRequired";
import Icon from "./General/Icon";
import Navbar from "./Navigation/Navbar";

function Layout({ children, user }) {
  const [chatInteraction, setchatInteraction] = useState(false);
  const [frame, setFrame] = useState(false);
  const [getHelp, setGetHelp] = useState(false);
  return (
    <>
      <div className="body-padding">
        <TermsRequired {...user} />
        <UserProfileRequired {...user} />
        <Navbar
          darkMenu={false}
          alert={{ alertEnable: false }}
          social={false}
          contact={false}
          {...user}
        />
        {children}

        {user?.company?.companyId && (
          <div className="chat">
            <div
              className="chat-circle shadow-lg"
              onClick={() => {
                setchatInteraction(!chatInteraction);
              }}
            >
              <Icon
                icon="comment"
                className="chat-circle-icon text-light mr-1"
              />
            </div>
            {chatInteraction && (
              <div className="chat-window bg-white shadow-lg rounded">
                <div className="chat-window-header p-4 bg-primary">
                  <h2 className="h3 font-weight-bold ml-2 mt-4">
                    <span className="d-block h4 mb-0">
                      👋 Howdy {user.company.contactFirstName}
                    </span>
                    <span className="d-block ml-1 text-secondary">
                      How can we help?
                    </span>
                  </h2>
                </div>
                {getHelp && (
                  <div
                    className="chat-window-inner bg-white position-relative p-4"
                    style={{ height: "calc(100% - 11.5rem)" }}
                  >
                    <button
                      // href="mailto:info@partnerhype.com?subject=Partner Hype Feature Request&body=What is the feature:%0D%0A%0D%0A%0D%0AWhy do you want it?%0D%0A%0D%0A%0D%0A"
                      className="stretched-link p-0 border-0 bg-transparent text-left font-weight-bold z-index-100"
                      style={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                      }}
                      onClick={() => {
                        setGetHelp(false);
                      }}
                    >
                      <Icon icon="times" className="" />
                    </button>

                    <h3 className="h4">Partner Hype Help</h3>
                    <p>
                      We're currently working hard on improving the platform.
                    </p>
                    <p>
                      If you require assistance or have any questions, we
                      request that you email us directly and we'll assist you as
                      soon as we can.
                    </p>
                    <p>
                      <a
                        href="mailto:info@partnerhype.com"
                        class="btn btn-secondary btn-sm"
                      >
                        info@partnerhype.com
                      </a>
                    </p>
                  </div>
                )}
                {frame && (
                  <div
                    className="chat-window-inner bg-white position-relative"
                    style={{ height: "calc(100% - 11.5rem)" }}
                  >
                    <button
                      // href="mailto:info@partnerhype.com?subject=Partner Hype Feature Request&body=What is the feature:%0D%0A%0D%0A%0D%0AWhy do you want it?%0D%0A%0D%0A%0D%0A"
                      className="stretched-link p-0 border-0 bg-transparent text-left font-weight-bold z-index-100"
                      style={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                      }}
                      onClick={() => {
                        setFrame(false);
                      }}
                    >
                      <Icon icon="times" className="" />
                    </button>

                    <iframe
                      title="Form"
                      src={frame}
                      style={{
                        border: 0,
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0",
                        left: "0",
                      }}
                    />
                  </div>
                )}
                {!frame && !getHelp && (
                  <div className="chat-window-inner bg-white  p-4">
                    <div className="card shadow rounded p-3 position-relative mb-3">
                      <h3 className="h6 mb-0">
                        <button
                          // href="mailto:info@partnerhype.com?subject=Partner Hype Feature Request&body=What is the feature:%0D%0A%0D%0A%0D%0AWhy do you want it?%0D%0A%0D%0A%0D%0A"
                          className="stretched-link p-0 border-0 bg-transparent text-left font-weight-bold d-block w-100"
                          onClick={() => {
                            setGetHelp(true);
                          }}
                        >
                          <Icon
                            icon="comment"
                            className="text-secondary small mr-3"
                          />
                          Ask a Question
                          <Icon
                            icon="arrow-right"
                            className="small float-right"
                          />
                        </button>
                      </h3>
                    </div>

                    <div className="card shadow rounded p-3 position-relative mb-3">
                      <h3 className="h6 mb-0">
                        <button
                          // href="mailto:info@partnerhype.com?subject=Partner Hype Feature Request&body=What is the feature:%0D%0A%0D%0A%0D%0AWhy do you want it?%0D%0A%0D%0A%0D%0A"
                          className="stretched-link p-0 border-0 bg-transparent text-left font-weight-bold d-block w-100"
                          onClick={() => {
                            setGetHelp(true);
                          }}
                        >
                          <Icon
                            icon="question"
                            className="text-secondary small mr-3"
                          />
                          Get Help
                          <Icon
                            icon="arrow-right"
                            className="small float-right"
                          />
                        </button>
                      </h3>
                    </div>

                    <div className="card shadow rounded p-3 position-relative mb-3">
                      <h3 className="h6 mb-0">
                        <button
                          // href="mailto:info@partnerhype.com?subject=Partner Hype Feature Request&body=What is the feature:%0D%0A%0D%0A%0D%0AWhy do you want it?%0D%0A%0D%0A%0D%0A"
                          className="stretched-link p-0 border-0 bg-transparent text-left font-weight-bold d-block w-100"
                          onClick={() => {
                            setFrame(
                              `https://databowl.typeform.com/s/ph-bug#email=${user.user.email}&firstname=${user?.company.contactFirstName}&lastname=${user.company.contactSurname}&company=${user.company.companyName}&user_id=${user.company.companyId}&url=${window.location.href}`
                            );
                          }}
                        >
                          <Icon
                            icon="bug"
                            className="text-secondary small mr-3"
                          />
                          Report a Bug
                          <Icon
                            icon="arrow-right"
                            className="small float-right"
                          />
                        </button>
                      </h3>
                    </div>

                    <div className="card shadow rounded p-3 position-relative mb-3">
                      <h3 className="h6 mb-0">
                        <button
                          // href="mailto:info@partnerhype.com?subject=Partner Hype Feature Request&body=What is the feature:%0D%0A%0D%0A%0D%0AWhy do you want it?%0D%0A%0D%0A%0D%0A"
                          className="stretched-link p-0 border-0 bg-transparent text-left font-weight-bold d-block w-100"
                          onClick={() => {
                            setFrame(
                              `https://databowl.typeform.com/s/ph-feature#email=${user.user.email}&firstname=${user?.company.contactFirstName}&lastname=${user.company.contactSurname}&company=${user.company.companyName}&user_id=${user.company.companyId}`
                            );
                          }}
                        >
                          <Icon
                            icon="lightbulb"
                            className="text-secondary small mr-3"
                          />
                          Suggest a Feature
                          <Icon
                            icon="arrow-right"
                            className="small float-right"
                          />
                        </button>
                      </h3>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
export default Layout;
