import React, { useState } from "react";

const Pricing = () => {
  const [leadsVolume, setLeadsVolume] = useState(0.54);
  const [leadAmount, setLeadAmount] = useState(300);
  const [sliderValue, setSliderValue] = useState(1);

  function sliderChange(e) {
    var sliderValue = parseInt(e.target.value);
    setSliderValue(sliderValue);

    var leads = 0;
    switch (sliderValue) {
      default:
        leads = 300;
        break;
      case 2:
        leads = 400;
        break;
      case 3:
        leads = 500;
        break;
      case 4:
        leads = 700;
        break;
      case 5:
        leads = 1000;
        break;
      case 6:
        leads = 2000;
        break;
      case 7:
        leads = 3000;
        break;
      case 8:
        leads = 5000;
        break;
      case 9:
        leads = 7000;
        break;
      case 10:
        leads = 10000;
        break;
      case 11:
        leads = 15000;
        break;
      case 12:
        leads = 20000;
        break;
      case 13:
        leads = 25000;
        break;
      case 14:
        leads = 30000;
        break;
      case 15:
        leads = 40000;
        break;
      case 16:
        leads = 50000;
        break;
      case 17:
        leads = 50000;
        break;
      // case 18: leads = 80000; break;
      // case 19: leads = 90000; break;
      // case 20: leads = 100000; break;
      // case 21: leads = 250000; break;
      // case 22: leads = 500000; break;
    }

    setLeadAmount(leads);

    const min = 0.022;
    const base = 0.48;
    const mid = 1.84;
    const low = -0.09622;

    const price =
      Math.round((mid * leads ** low - base / leads - (1 - base)) * 100) / 100;
    setLeadsVolume(price < min ? min : price);
  }
  return (
    <div className="bg-light">
      <section id="pricing" className="bg-half-down bg-primary">
        <div className="container py-5">
          <h2 className="text-dark font-family-display text-center mb-4">
            Our Pricing
          </h2>
          <p className="text-dark text-center mb-5">
            Partner Hype only charge lead buyers for delivering good leads and
            for any lead validation usage.
            <br /> Pricing is based on blocks, with discounts available for
            higher quantities.
            <br /> Contact us to discuss your buying requirements.
            <br />
            <br /> <strong>Example Pricing:</strong>
          </p>

          <div className="row justify-content-center mb-5 text-dark">
            <div className="col-lg-6 pt-3">
              <input
                type="range"
                name="leadsVolume"
                id="leadsVolume"
                onChange={sliderChange}
                min="1"
                max="17"
                value={`${sliderValue ? sliderValue : 5}`}
                className="custom-range"
              />
            </div>
            <div className="col-lg-3">
              <div className="p-3 rounded bg-primary text-center font-weight-bold">
                {leadAmount.toLocaleString()}
                {sliderValue === 17 && "+"} leads
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-12 mb-4">
              <div className="card shadow bg-white rounded-lg h-100">
                <div className="card-body">
                  <h3 className="h4 card-title text-success font-family-display mb-0">
                    Sellers
                  </h3>
                  <div className="mb-3">
                    <span className="h2 font-weight-bold font-family-body">
                      £0.00
                    </span>
                    <span className="d-block small">
                      <span className="small">per delivered lead</span>
                    </span>
                  </div>
                  <p>
                    Lead Sellers get all the benefits of our marketplace{" "}
                    <u className="font-weight-bold">100% FREE</u>.
                  </p>
                  <ul className="fa-ul small ml-4">
                    <li className="mb-2">
                      <span className="fa-li text-success">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Free Due Diligence Certification
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-success">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Easy to use API
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-success">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Full technical documentation
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-success">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Validation and formats outlined
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-success">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Support team to assistance
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-success">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Easy Application Process
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mb-4">
              <div className="card shadow bg-white rounded-lg h-100">
                <div className="card-body">
                  <h3 className="h4 card-title text-info font-family-display mb-0">
                    Buyer
                  </h3>
                  <div className="mb-3">
                    <span className="h2 font-weight-bold font-family-body">
                      {sliderValue !== 17 ? (
                        `£ ${leadsVolume}`
                      ) : (
                        <span className="h4 font-weight-bold">
                          Contact for Pricing
                        </span>
                      )}
                    </span>
                    <span className="d-block small">
                      <span className="small">
                        per delivered lead + your lead cost
                      </span>
                    </span>
                  </div>
                  <p>
                    Includes everything for buyers to receive leads in the
                    format they need and avoid duplicates.
                  </p>
                  <ul className="fa-ul small ml-4">
                    <li className="mb-2">
                      <span className="fa-li text-info">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Campaign Setup
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-info">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Lead Delivery
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-info">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Single point for all distributors
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-info">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Data Standardisation
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-info">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Required Fields checked
                    </li>
                    {/* <li className="mb-2">
                <span className="fa-li text-info">
                  <i className="fa fa-check"></i>
                </span>{" "}
                Campaign De Dupe
              </li> */}
                    <li className="mb-2">
                      <span className="fa-li text-info">
                        <i className="fa fa-check"></i>
                      </span>{" "}
                      Routing to upto 5 end points
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-primary">
                        <i className="fas fa-asterisk small opacity-40"></i>
                      </span>{" "}
                      Custom Rulegroups
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-primary">
                        <i className="fas fa-asterisk small opacity-40"></i>
                      </span>{" "}
                      Phone Validation
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-primary">
                        <i className="fas fa-asterisk small opacity-40"></i>
                      </span>{" "}
                      Email Validation
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-primary">
                        <i className="fas fa-asterisk small opacity-40"></i>
                      </span>{" "}
                      Address Validation
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-primary">
                        <i className="fas fa-asterisk small opacity-40"></i>
                      </span>{" "}
                      Suppression Files
                    </li>
                    <li className="mb-2">
                      <span className="fa-li text-primary">
                        <i className="fas fa-asterisk small opacity-40"></i>
                      </span>{" "}
                      De Dupe against other campaigns
                    </li>
                  </ul>
                  <div className="small font-italic text-right">
                    <i className="fas fa-asterisk small opacity-40"></i>{" "}
                    Additional costs apply.
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-12 mb-4">
            <div className="card shadow bg-white rounded-lg h-100">
              <div className="card-body">
                <h3 className="h5 card-title text-secondary font-family-display mb-2">
                  Pro Buyer
                </h3>
                <div className="mb-3">
                  <span className="h1 font-weight-bold font-family-body">
                    £0.40<span className="small text-warning">*</span>
                  </span>
                  <span className="d-block small">
                    <span className="small">per delivered lead</span>
                  </span>
                </div>
                <p>
                  Additional validation, verification and custom rule groups.
                </p>
                <ul className="fa-ul small ml-4">
                  <li className="mb-2">
                    <span className="fa-li text-secondary">
                      <i className="fa fa-check"></i>
                    </span>{" "}
                    Everything in Basic
                  </li>
                  <li className="mb-2">
                    <span className="fa-li text-secondary">
                      <i className="fa fa-check"></i>
                    </span>{" "}
                    Custom Rulegroups
                  </li>
                  <li className="mb-2">
                    <span className="fa-li text-secondary">
                      <i className="fa fa-check"></i>
                    </span>{" "}
                    Phone Validation
                  </li>
                  <li className="mb-2">
                    <span className="fa-li text-secondary">
                      <i className="fa fa-check"></i>
                    </span>{" "}
                    Email Validation
                  </li>
                  <li className="mb-2">
                    <span className="fa-li text-secondary">
                      <i className="fa fa-check"></i>
                    </span>{" "}
                    Address Validation
                  </li>
                  <li className="mb-2">
                    <span className="fa-li text-secondary">
                      <i className="fa fa-check"></i>
                    </span>{" "}
                    Suppression Files
                  </li>
                  <li className="mb-2">
                    <span className="fa-li text-secondary">
                      <i className="fa fa-check"></i>
                    </span>{" "}
                    De Dupe against other campaigns
                  </li>
                </ul>
                <div className="small font-italic text-right">
                  <span className="text-warning">*</span> In addition to your
                  lead costs
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
