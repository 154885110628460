import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import BreadCrumb from "./../../Components/Navigation/BreadCrumb";
import OpportunitiesList from "../../Components/Marketplace/List";
// import Loading from "./../../Components/General/Loading";
import Error from "../../Components/General/Error";
import { getOpportunities } from "../../Components/API/API";

import Skeleton from "react-loading-skeleton";

import site from "./../../_data/marketTheme.json";

const loadingData = {
  fields: {
    id: <Skeleton />,
    meta: <Skeleton />,
    applyingCompanies: false,
    name: <Skeleton />,
    Name: <Skeleton />,
    ShortDesc: <Skeleton />,
    companyName: <Skeleton />,
    featured: <Skeleton />,
    nolarge: <Skeleton />,
  },
};

function Opportunities({
  user,
  meta,
  accessToken,
  latest = false,
  companyId,
  company,
}) {
  const [opportunities, setOpportunities] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [applied, setApplied] = useState(false);

  const [searchDone, setSearchDone] = useState(false);
  const [category, setCategory] = useState(false);
  const [audience, setAudience] = useState(false);
  const [sector, setSector] = useState(false);
  const [paymentModel, setPaymentModel] = useState(false);
  const [country, setCountry] = useState(false);
  const [capabilities, setCapabilities] = useState(false);

  // TODO: Filtering to be multiple options (checkboxes)
  // TODO: Filtering to be saved into the user session

  let filters = "";
  //TODO: move to an object and loop
  //TODO: look at moving to URL based query params
  if (
    searchDone === false &&
    (category ||
      audience ||
      sector ||
      paymentModel ||
      country ||
      capabilities ||
      applied)
  ) {
    filters = "";
    if (category) {
      filters = filters + `,FIND("${category}",{Category})`;
    }
    if (audience) {
      filters = filters + `,FIND("${audience}",{Audience})`;
    }
    if (capabilities) {
      filters = filters + `,FIND("${capabilities}",{Capabilities})`;
    }
    if (sector) {
      filters = filters + `,FIND("${sector}",{sector})`;
    }
    if (paymentModel) {
      filters = filters + `,FIND("${paymentModel}",{paymentModel})`;
    }
    if (country) {
      filters = filters + `,FIND("${country}",{country})`;
    }
    if (applied === "Yes") {
      filters = filters + `,FIND("${meta.company}",{applyingCompanies})`;
    }
    if (applied === "No") {
      filters = filters + `,FIND("${meta.company}",{applyingCompanies})=0`;
    }
    if (!company.validDD) {
      filters = filters + `,{opportunityDueDilForce}=FALSE()`;
    }
  } else {
    filters = "";
  }

  useEffect(() => {
    if (!searchDone && companyId.length) {
      getOpportunities(user?.sub, accessToken, companyId, filters)
        .then((res) => {
          setOpportunities(res);
          setSearchDone(true);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsError(true);
          setSearchDone(true);
        });
    }
  }, [
    opportunities,
    searchDone,
    latest,
    filters,
    accessToken,
    user,
    companyId,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {site.OpportunitiesName} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: "Browsing:", url: "" },
          {
            title: site.OpportunitiesName,
            url: "/marketplace/opportunities/",
          },
        ]}
      />
      {isLoading ? (
        <>
          <OpportunitiesList
            title={site.OpportunitiesName}
            meta={meta}
            site={site}
            includeSearch={<Skeleton />}
            setSearchDone={<Skeleton />}
            setCategory={<Skeleton />}
            setAudience={<Skeleton />}
            setSector={<Skeleton />}
            setPaymentModel={<Skeleton />}
            setCapabilities={<Skeleton />}
            setCountry={<Skeleton />}
            setApplied={<Skeleton />}
            isLoading={isLoading}
            opportunities={[
              loadingData,
              loadingData,
              loadingData,
              loadingData,
              loadingData,
              loadingData,
              loadingData,
              loadingData,
            ]}
            category={<Skeleton />}
            audience={<Skeleton />}
            sector={<Skeleton />}
            paymentModel={<Skeleton />}
            capabilities={<Skeleton />}
            country={<Skeleton />}
            applied={<Skeleton />}
          />
        </>
      ) : (
        <>
          {!isError ? (
            <OpportunitiesList
              title={site.OpportunitiesName}
              meta={meta}
              site={site}
              includeSearch={true}
              setSearchDone={setSearchDone}
              setCategory={setCategory}
              setAudience={setAudience}
              setSector={setSector}
              setPaymentModel={setPaymentModel}
              setCapabilities={setCapabilities}
              setCountry={setCountry}
              setApplied={setApplied}
              opportunities={opportunities}
              category={category}
              audience={audience}
              sector={sector}
              paymentModel={paymentModel}
              capabilities={capabilities}
              country={country}
              applied={applied}
            />
          ) : (
            <Error error={isError} />
          )}
        </>
      )}
    </>
  );
}
export default Opportunities;
