import React from "react";
import LoginSignupButtons from "./../Authentication/LoginSignupButtons";
import Icon from "../General/Icon";

function HomeHero({ user=false, site, connectImage, isAuthenticated=false }) {
  return (
    <main
      className={`${site.HomeBG} ${
        site.HomeBG !== site.NavBGDark && site.NavDark === true
          ? "py-xl-5"
          : "pb-xl-5"
      }`}
    >
      <div className="container-fluid">
        <div className="row contained-xl justify-content-center">
          <div className="order-lg-2 col-10 col-md-8 col-lg-5 align-self-center py-lg-5">
            <img
              src={connectImage}
              alt=""
              className="img-fluid opacity-100 w-100"
            />
          </div>
          <div className="order-lg-1 col-11 col-lg-5 align-self-center my-xl-5 pt-lg-4 pb-lg-5 text-center text-lg-left">
            <h1
              className="display-2 mb-4"
              dangerouslySetInnerHTML={{
                __html: site.BrandStrapHTML
                  ? site.BrandStrapHTML
                  : site.BrandStrap,
              }}
            />
            <p
              className="lead font-weight-normal mb-4 mb-lg-5"
              dangerouslySetInnerHTML={{
                __html: site.BrandIntro,
              }}
            />
            <LoginSignupButtons className="px-lg-5 mx-2 mb-5 mb-lg-0 mx-lg-0 mr-lg-3" />
            <hr className="col-2 col-lg-1 border-thick border-secondary mx-auto mx-lg-0 my-4 px-0" />
            <a href="https://www.partnerhype.com"><Icon icon="chevron-left" className="text-secondary mr-1"/> View more information on our homepage</a>
          </div>
        </div>
      </div>
    </main>
  );
}
export default HomeHero;
