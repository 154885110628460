import React, { useState } from "react";
import { Link } from "react-router-dom";

function YourApplicationsRow({
  recId,
  opportunityName,
  opportunityCategory,
  opportunitySector,
  opportunityCompany,
  status,
  opportunityTerms,
  instructions,
  IntegrationDocId,
  opportunity,
  viewing,
}) {
  const [isViewing, setIsViewing] = useState(viewing === recId ? true : false);

  return (
    <div className="col-12 col-md-6 col-lg-12">
      <div className=" bg-white mb-3 px-4 py-4 px-lg-3 py-lg-2">
        <div className="row">
          <div className="col-12 mb-3 mb-lg-0 col-lg-2 align-self-center">
            <span className="font-weight-bold">{opportunityName}</span>
          </div>
          <div className="col-6 mb-3 mb-lg-0 col-lg-2 align-self-center">
            <span className="d-lg-none text-uppercase mr-1 font-weight-bold">
              Category:{" "}
            </span>
            <span>{opportunityCategory}</span>
          </div>
          <div className="col-6 mb-3 mb-lg-0 col-lg-2 align-self-center">
            <span className="d-lg-none text-uppercase mr-1 font-weight-bold">
              Sector:{" "}
            </span>
            <span>{opportunitySector}</span>
          </div>
          <div className="col-6 mb-3 mb-lg-0 col-lg-2 align-self-center">
            <span className="d-lg-none text-uppercase mr-1 font-weight-bold">
              Company:{" "}
            </span>
            <span>{opportunityCompany}</span>
          </div>
          <div className="col-6 mb-3 mb-lg-0 col-lg-2    text-lg-center  align-self-center">
            <span className={`my-1 my-lg-0 d-block`}>
              {status === "Pending" && (
                <span className="bg-warning px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                  <i className="fa fa-clock"></i> Pending
                </span>
              )}
              {(status === "Accepted" || status === "Live") && (
                <span className="d-lg-inline bg-success px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                  <i className="fa fa-check"></i> {status}
                </span>
              )}
              {(status === "Rejected" || status === "Removed") && (
                <span className="d-lg-inline bg-danger px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                  <i className="fa fa-xmark"></i> {status}
                </span>
              )}
            </span>
          </div>
          <div className="col-12 pt-2 pt-lg-0 col-lg-2 px-lg-2 text-right text-lg-center align-self-center">
            <button
              className={`btn d-block d-lg-inline btn-primary btn-sm col-12 col-lg-6 py-0 mt-lg-1 px-1`}
              onClick={function (e) {
                setIsViewing(!isViewing);
              }}
            >
              Details{" "}
              {isViewing ? (
                <i className="ml-2 fa fa-chevron-up"></i>
              ) : (
                <i className="ml-2 fa fa-chevron-down"></i>
              )}
            </button>
          </div>
        </div>
        {isViewing && (
          <div className="bg-light p-4 mt-3 mb-2">
            <h2 className="h5">Campaign Information:</h2>
            <h3 className="h5 text-secondary">{opportunityName}</h3>

            {opportunityTerms && (
              <div className="mt-4">
                <h3 className="h6 text-secondary mt-4">Terms</h3>
                <div>{opportunityTerms}</div>
              </div>
            )}

            {instructions && (
              <div className="mt-4">
                <h3 className="h6 text-secondary">Instruction</h3>
                <div>{instructions}</div>
              </div>
            )}
            <div className="mt-4">
              {IntegrationDocId && (
                <Link
                  to={`/api/docs/${IntegrationDocId}`}
                  className="btn btn-sm btn-primary mr-2"
                >
                  API Docs
                </Link>
              )}
              <Link
                to={`/marketplace/opportunities/${opportunity[0]}`}
                className="btn btn-sm btn-secondary"
              >
                View Original Ad
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default YourApplicationsRow;
