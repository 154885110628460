import React from "react";
import site from "./../../../_data/marketTheme.json";
import countryList from "./../../../_data/countryList.json";

import Input from "../../../Components/Forms/Input";
import Select from "../../../Components/Forms/Select";
import YesNo from "../../../Components/Forms/YesNo";

function CreateForm({ errors, register, watchOpportunityDateType }) {
  return (
    <>
      <h3 className="font-family-display h4 mb-3">Your Details</h3>
      <fieldset className={`my-4 bg-${site.createAdvertFormBG} mb-5 p-4`}>
        <div className="row">
          <div className="col-lg-6">
            <Input
              name="opportunityContactName"
              placeholder="John Smith"
              validation={{
                required: "This is required.",
              }}
              error={errors?.opportunityContactName?.message}
              register={register}
              dark={false}
              label="Contact Name"
              type="text"
            />
          </div>
          <div className="col-lg-6">
            <Input
              name="opportunityContactCompany"
              placeholder="ACME"
              validation={{
                required: "This is required.",
              }}
              error={errors?.opportunityContactCompany?.message}
              register={register}
              dark={false}
              label="Company"
              type="text"
            />
          </div>
          <div className="col-lg-6">
            <Input
              name="opportunityContactEmail"
              placeholder="john.smith@email.com"
              validation={{
                required: "This is required.",
              }}
              error={errors?.opportunityContactEmail?.message}
              register={register}
              dark={false}
              label="Contact Email"
              type="text"
            />
          </div>
          <div className="col-lg-6">
            <Input
              name="opportunityContactPhone"
              placeholder="+441234567890"
              validation={{
                required: "This is required.",
              }}
              error={errors?.opportunityContactPhone?.message}
              register={register}
              dark={false}
              label="Contact Phone"
              type="text"
            />
          </div>
        </div>

        <Input
          name="opportunityContactBuyingHistory"
          placeholder="X years"
          validation={{
            required: "This is required.",
          }}
          error={errors?.opportunityContactBuyingHistory?.message}
          register={register}
          dark={false}
          label="How long has your company been buying leads from 3rd party providers?"
          type="number"
        />
      </fieldset>

      <h3 className="font-family-display h4 mb-3">Your Adverts Details</h3>
      <fieldset className={`my-4 bg-${site.createAdvertFormBG} mb-5 p-4`}>
        <Input
          name="opportunityName"
          placeholder="eg: Campaign - Company - Winter 2023"
          error={errors?.opportunityName?.message}
          register={register}
          dark={false}
          label="Advert/Campaign Name"
          helper="Keep it short and snappy, something memorable, include your company name and campaign, maybe even the period it's running"
          validation={{
            required: "This is required.",
            minLength: {
              value: 5,
              message: "Min length 5, Max length 100",
            },
            maxLength: {
              value: 100,
              message: "Min length 5, Max length 100",
            },
          }}
        />
        <Input
          name="opportunityShortDesc"
          placeholder="..."
          error={errors?.opportunityShortDesc?.message}
          register={register}
          dark={false}
          label="Summary"
          helper="A short description of your campaign, like the length of a tweet as described to a potential lead."
          validation={{
            required: "This is required.",
            minLength: {
              value: 20,
              message: "Min length 20, Max length 200",
            },
            maxLength: {
              value: 200,
              message: "Min length 20, Max length 200",
            },
          }}
        />
        <Input
          name="opportunityDesc"
          placeholder="..."
          error={errors?.opportunityDesc?.message}
          register={register}
          dark={false}
          label="Description"
          type="textarea"
          helper="Describe your campaign to a lead generator... This should be a long description, with information about your product, and how you work."
          validation={{
            required: "This is required.",
            minLength: {
              value: 20,
              message: "Min length 20, Max length 1000.",
            },
            maxLength: {
              value: 1000,
              message: "Min length 20, Max length 1000.",
            },
          }}
        />
        {/* <Input
          name="opportunityImages"
          error={errors?.opportunityImages?.message}
          register={register}
          dark={false}
          label="Images"
          type="url"
          placeholder="http://www..."
          helper={
            "Enter a URL for your images or a zip of images: wetransfer etc."
          }
        /> */}{" "}
        <Select
          name="opportunityDateType"
          register={register}
          error={errors?.opportunityDateType?.message}
          label="Campaign Dates"
          dark={false}
          multiple={false}
          options={[
            "Running Now - No End Date",
            "Specific Start and End Date",
            "Running Now - Specific End Date",
          ]}
          validation={{
            required: "This is required.",
          }}
        />
        <div className="row">
          {watchOpportunityDateType === "Specific Start and End Date" && (
            <>
              <div className="col-lg-6">
                <Input
                  name="opportunityStartDate"
                  error={errors?.opportunityStartDate?.message}
                  register={register}
                  dark={false}
                  label="Campaign Start Date"
                  type="date"
                  validation={{
                    required: "This is required.",
                  }}
                />
              </div>
              <div className="col-lg-6">
                <Input
                  name="opportunityEndDate"
                  error={errors?.opportunityEndDate?.message}
                  register={register}
                  dark={false}
                  label="Campaign End Date"
                  type="date"
                  validation={{
                    required: "This is required.",
                  }}
                />
              </div>
            </>
          )}
          {watchOpportunityDateType === "Running Now - Specific End Date" && (
            <div className="col-lg-12">
              <Input
                name="opportunityEndDate"
                error={errors?.opportunityEndDate?.message}
                register={register}
                dark={false}
                label="Campaign End Date"
                type="date"
                validation={{
                  required: "This is required.",
                }}
              />
            </div>
          )}

          <div className="col-lg-6">
            <Select
              name="opportunitySector"
              register={register}
              error={errors?.opportunitySector?.message}
              label="Sector"
              dark={false}
              multiple={false}
              defaultValue="Select the campaign sector"
              options={[
                "Select the campaign sector",
                "Life Insurance",
                "Health Insurance",
                "Vehicle Insurance",
                "Home Insurance",
                "Travel Insurance",
                "Broadband",
                "Phone",
                "Energy",
                "Mortgage/Remortgage",
                "Medical Claims",
                "PPI",
                "B2B",
                "Product/Brand",
                "Subscription",
                "Other (see description)",
              ]}
              validation={{
                required: "This is required.",
              }}
            />
          </div>

          <div className="col-lg-6">
            <Select
              name="opportunityCountry"
              register={register}
              error={errors?.opportunityCountry?.message}
              label="Campaign Country"
              dark={false}
              defaultValue={"Select the Campaign Country:"}
              options={["Select the Campaign Country:", ...countryList]}
              validation={{
                required: "This is required.",
              }}
            />
          </div>

          <div className="col-lg-12">
            <Input
              name="opportunityAudience"
              placeholder="..."
              validation={{
                required: "This is required.",
              }}
              error={errors?.opportunityAudience?.message}
              register={register}
              dark={false}
              label="Audience Info"
              helper="Age, Gender, Geo, Financial and any other information for your target audience."
              type="textarea"
            />
          </div>
        </div>
      </fieldset>

      <h3 className="font-family-display h4 mb-3">Your Needs</h3>
      <fieldset className={`my-4 bg-${site.createAdvertFormBG} mb-5 p-4`}>
        <div className="row">
          <div className="col-lg-6">
            <Input
              name="opportunityLimit"
              placeholder="XX Leads"
              validation={{
                required: "This is required.",
              }}
              error={errors?.opportunityLimit?.message}
              register={register}
              dark={false}
              label="Lead Volume Required"
              type="number"
            />
          </div>

          <div className="col-lg-6">
            <Select
              name="opportunityLimitPeriod"
              register={register}
              error={errors?.opportunityLimitPeriod?.message}
              label="Over period"
              multiple={false}
              dark={false}
              options={["Day", "Week", "Month", "Quarter", "Year"]}
              validation={{
                required: "This is required.",
              }}
            />
          </div>

          <div className="col-lg-6">
            <Input
              name="opportunityIdealCPL"
              placeholder="0"
              validation={{
                required: "This is required.",
              }}
              error={errors?.opportunityIdealCPL?.message}
              register={register}
              dark={false}
              label="Ideal CPL"
              type="decimal"
            />{" "}
          </div>
          <div className="col-lg-6">
            <Select
              name="opportunityConversionRate"
              register={register}
              error={errors?.opportunityConversionRate?.message}
              label="Desired Conversion Rate"
              multiple={false}
              dark={false}
              options={[
                "0-5%",
                "5-10%",
                "10-20%",
                "20-30%",
                "30-40%",
                "40-50%",
                "50-60%",
                "60-70%",
                "70-80%",
                "80%+",
              ]}
              validation={{
                required: "This is required.",
              }}
            />
          </div>
          <div className="col-lg-12 my-3">
            <YesNo
              name="opportunityMultiSellLeads"
              validation={{
                required: "This is required.",
              }}
              error={errors?.dueDiligenceDPP?.message}
              register={register}
              dark={false}
              label="Would you buy 'multi sell' leads?"
              helper="This is leads that go to more than one buyer to get several quotes"
              type="Radio"
            />
          </div>
          <div className="col-lg-12">
            <Select
              name="opportunityCapabilities"
              helper={"Select multiple by holding cmd/control when clicking"}
              register={register}
              error={errors?.opportunityCapabilities?.message}
              label="Select Traffic Types"
              // allowSelectAll={true}
              multiple={true}
              defaultValue={[false]}
              dark={false}
              options={[
                "Co-Reg",
                "Capabilities",
                "Landing Page",
                "Other (see description)",
                "Banners",
                "Cashback",
                "Content",
                "Email",
                "Face to Face",
                "Native Search",
                "SMS",
                "Social Influencer",
                "Tele Leads",
                "Push",
                "Exit Traffic",
                "Pop Up",
              ]}
              validation={{
                required: "This is required.",
              }}
            />
          </div>
          <div className="col-lg-6 my-3">
            <YesNo
              name="opportunityContentApproval"
              validation={{
                required: "This is required.",
              }}
              error={errors?.opportunityContentApproval?.message}
              register={register}
              dark={false}
              label="Do you require approval for content such as Ads, Landing Pages and/or Email?"
              type="Radio"
            />
          </div>
          {/* <div className="col-lg-6 my-3">
            <YesNo
              name="opportunityLeadsResell"
              validation={{
                required: "This is required.",
              }}
              error={errors?.dueDiligenceDPP?.message}
              register={register}
              dark={false}
              label="Will you be looking to re-sell leads after your use? Or if they aren't suitable?"
              type="Radio"
            />
          </div> */}
        </div>
      </fieldset>
      <h3 className="font-family-display h4 mb-3">Your Process</h3>
      <fieldset className={`my-4 bg-${site.createAdvertFormBG} mb-5 p-4`}>
        <Input
          name="opportunityDeliveryMechanism"
          error={errors?.opportunityDeliveryMechanism?.message}
          register={register}
          dark={false}
          type="textarea"
          label="How do you want to receive leads?"
          placeholder={"..."}
          helper={"Explain your API, link to docs, FTP server details etc."}
          validation={{
            required: "This is required.",
          }}
        />
        <Input
          name="opportunityLeadProcess"
          error={errors?.opportunityDeliveryMechanism?.message}
          register={register}
          dark={false}
          type="textarea"
          label="Tell us about your Lead and Sales Process"
          placeholder={"..."}
          helper={
            "Where do your leads go, what do you do with them and how fast?<br/><br/><ul><li>Do you deliver to call centres for sales staff to dial instantly?</li><li>CRM for automation and nurturing?</li><li>Send links to book appointments?</li><li>Distribute to different teams?</li></ul>This information will assist us with your campaign setup."
          }
          validation={{
            required: "This is required.",
          }}
        />

        <Select
          name="opportunityRejectionPeriod"
          register={register}
          error={errors?.opportunityRejectionPeriod?.message}
          label="When do you report rejections to your suppliers"
          helper={`Choose the longest period you work with, eg: If you sometimes report back at the end of the month, c`}
          multiple={false}
          defaultValue={"Select Period:"}
          dark={false}
          options={["Select Period:", "Instant", "Daily", "Weekly", "Monthly"]}
          validation={{
            required: "This is required.",
          }}
        />
        <div className="row mb-4">
          <div className="col-lg-6">
            <Input
              name="opportunityRejectionReasons"
              placeholder="eg: data validation, lack of intent, not correct location."
              validation={{
                required: "This is required.",
              }}
              error={errors?.opportunityRejectionReasons?.message}
              register={register}
              dark={false}
              label="Describe why you may reject leads?"
              type="textarea"
            />
          </div>
          <div className="col-lg-6">
            <Input
              name="opportunityFeedback"
              validation={{
                required: "This is required.",
              }}
              placeholder="eg: webhook data, monthly reports"
              error={errors?.opportunityFeedback?.message}
              register={register}
              dark={false}
              label="How do you feedback results/outcomes?"
              type="textarea"
            />
          </div>
        </div>
        <YesNo
          name="opportunityShareProcess"
          validation={{
            required: "This is required.",
          }}
          error={errors?.dueDiligenceDPP?.message}
          register={register}
          dark={false}
          label="Would you be happy to share your sales process/and or script with a lead generator?"
          type="Radio"
        />
      </fieldset>

      <h3 className="font-family-display h4 mb-3">Due Diligence:</h3>
      <fieldset className={`my-4 bg-${site.createAdvertFormBG} mb-5 p-4`}>
        <Input
          name="opportunityDueDil"
          error={errors?.opportunityDueDil?.message}
          register={register}
          dark={false}
          type="textarea"
          label="Due Diligence Requirements"
          helper="Outline any due diligence requirements you have, or supply a link to your due diligence document."
          placeholder="eg: If they must complete an internal process, please describe it"
        />
        <div className="mt-4">
          <YesNo
            name="dueDiligenceDPP"
            validation={{
              required: "This is required.",
            }}
            error={errors?.dueDiligenceDPP?.message}
            register={register}
            dark={false}
            label="Do you only want to require applicants to have a valid Partner Hype Due Diligence Certificate?"
            type="Radio"
          />
        </div>
      </fieldset>

      <h3 className="font-family-display h4 mb-3">Your Terms:</h3>
      <fieldset className={`my-4 bg-${site.createAdvertFormBG} mb-5 p-4`}>
        <Input
          name="opportunityTerms"
          error={errors?.opportunityTerms?.message}
          register={register}
          dark={false}
          type="textarea"
          label="Campaign Terms and Conditions"
          helper="Outline your terms and conditions for working with lead generators."
          placeholder="eg: only work with X types of generators, are NDA's required etc."
          validation={{
            required: "This is required.",
          }}
        />
        <Input
          name="opportunityPaymentTerms"
          error={errors?.opportunityPaymentTerms?.message}
          register={register}
          dark={false}
          type="textarea"
          label="Your Payment Terms"
          helper="Outline your lead Generator temrms"
          placeholder="eg: payments made within 30 days."
          validation={{
            required: "This is required.",
          }}
        />
      </fieldset>
    </>
  );
}
export default CreateForm;
