import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import Input from "../../Components/Forms/Input";

import { doAccountDeletion, updateProfile } from "./../../Components/API/API";

import site from "./../../_data/marketTheme.json";
import Icon from "../../Components/General/Icon";
import Error from "../../Components/General/Error";

import BreadCrumb from "../../Components/Navigation/BreadCrumb";

function Form({
  user,
  meta,
  company,
  companyId,
  isLoading,
  accessToken,
  isAuthenticated,
}) {
  const [requestDeletion, setRequestDeletion] = useState(false);
  const [accountUpdate, setAccountUpdate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [newLogo, setNewLogo] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  let fistProfile = false;
  if (
    !isLoading &&
    isAuthenticated &&
    (meta.generateLeadTermsAccepted || meta.postOpportunitiesTermsAccepted) &&
    company?.companyId?.length &&
    (!company.companyAddress || !company.companyName || !company.companyContact)
    // || !company.companyEmail
  ) {
    fistProfile = true;
  }

  useEffect(() => {
    let defaultValues = {};
    defaultValues.id = company?.companyId;
    defaultValues.name = user.name;
    defaultValues.email = user.email;
    defaultValues.contactLinkedIn = company?.contactLinkedIn;

    defaultValues.contactFirstName = company?.contactFirstName;
    defaultValues.contactSurname = company?.contactSurname;

    defaultValues.companyBio = company?.companyBio;
    defaultValues.companyLogo = company?.companyLogo;
    defaultValues.companyName = company?.companyName;
    defaultValues.companyContact = company?.companyContact;
    defaultValues.companyAddress = company?.companyAddress;
    defaultValues.companyEmail = company?.companyEmail
      ? company.companyEmail
      : user.email;
    defaultValues.companyPhone = company?.companyPhone;
    reset({ ...defaultValues });
  }, [user, company, reset]);

  function handleDeletion(e) {
    e.preventDefault();
    doAccountDeletion(user.email, company?.companyId);
    setRequestDeletion(true);
  }

  function handProfileUpdate(data) {
    // data.companyEmail = user.email;
    if (data?.companyLogo?.item(0)) {
      const fileData = new FormData();
      fileData.append("file", data.companyLogo.item(0));
      fileData.append("upload_preset", "uploads");
      fileData.append("cloud_name", "partner-hype");
      fetch("  https://api.cloudinary.com/v1_1/partner-hype/image/upload", {
        method: "post",
        body: fileData,
      })
        .then((resp) => resp.json())
        .then((fileData) => {
          data.companyLogo = fileData.url;
          updateProfile({ ...data }, user?.sub, accessToken, companyId)
            .then((res) => {
              setAccountUpdate(true);
              setIsError(false);
              if (fistProfile) {
                window.location.href = "/?first=true";
              }
            })
            .catch((error) => {
              setIsError(true);
            });
        })
        .catch((err) => console.log(err));
    } else {
      updateProfile({ ...data }, user?.sub, accessToken, companyId)
        .then((res) => {
          setAccountUpdate(true);
          setIsError(false);
          if (fistProfile) {
            window.location.href = "/?first=true";
          }
        })
        .catch((error) => {
          setIsError(true);
        });
    }
  }

  register("id");

  return (
    <>
      <Helmet>
        <title>
          {site.account} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: site.account,
            url: "./",
          },
        ]}
      />
      {isError ? (
        <Error />
      ) : (
        <main className="loading bg-light py-3">
          {fistProfile && (
            <div className="bg-primary mt-2 p-3 mb-4">
              <div className="text-center mb-0 text-light">
                👋 Hello... before we begin, please complete your profile 👍
              </div>
            </div>
          )}
          <div className="container-fluid">
            <div className="row justify-content-center justify-content-lg-start contained-xl ">
              <div className="col-11 col-lg-6 offset-lg-1 align-content-center py-lg-5">
                <form onSubmit={handleSubmit(handProfileUpdate)}>
                  {/* <input
                   {...register("id")} type="hidden" /> */}

                  <fieldset className="">
                    <h1 className="h2 font-family-display mb-2">
                      Your Account
                    </h1>
                    <p className="small mb-4">
                      Your company profile information is when you post or
                      appply to an opportunity.
                    </p>
                    {/* <Input
                    
                  label="Your Name"
                  value={user.name}
                  name="name"
                  error={name?.opportunityEndDate?.message}
                  validation={{
                      required: "This is required.",
                    }}
                  dark={false}
                  // register={register}
                /> */}

                    <Input
                      label="Login Email"
                      value={user.email}
                      name="email"
                      type="email"
                      disabled={true}
                      required
                      className="opacity-40 disabled"
                      dark={false}
                      // register={register}
                    />
                    <p className="small text-right mb-0">
                      If you wish to change your email address, please contact
                      us.
                    </p>
                  </fieldset>

                  <fieldset className="my-5">
                    <div className="row">
                      <div className="col-lg-6">
                        <Input
                          label="Your First Name"
                          dark={false}
                          register={register}
                          name="contactFirstName"
                          error={errors?.contactFirstName?.message}
                          validation={{
                            required: "This is required.",
                          }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <Input
                          label="Your Surname"
                          dark={false}
                          register={register}
                          name="contactSurname"
                          error={errors?.contactSurname?.message}
                          validation={{
                            required: "This is required.",
                          }}
                        />
                      </div>
                    </div>
                    <Input
                      label="Email Address for notifications"
                      helper="This can be your account email, but can also be a shared team email"
                      dark={false}
                      register={register}
                      name="companyEmail"
                      error={errors?.companyEmail?.message}
                      validation={{
                        required: "This is required.",
                      }}
                      type="email"
                    />

                    <div className="row">
                      <div className="col-lg-6">
                        <Input
                          label="Phone Number"
                          dark={false}
                          register={register}
                          name="companyPhone"
                          error={errors?.companyPhone?.message}
                          validation={{
                            required: "This is required.",
                            pattern: {
                              value:
                                /[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}/,
                              message: "Please enter a valid phone number",
                            },
                          }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <Input
                          label="LinkedIn Profile URL"
                          placeholder="https://"
                          dark={false}
                          register={register}
                          name="contactLinkedIn"
                          error={errors?.contactLinkedIn?.message}
                          validation={{
                            // required: "This is required.",
                            pattern: {
                              value: /(https:\/\/www.linkedin.com)/,
                              message:
                                "Enter a valid LinkedIn URL eg: https://www.linkedin.com/in/....",
                            },
                          }}
                        />
                      </div>
                    </div>

                    <Input
                      label="Company Name"
                      dark={false}
                      register={register}
                      name="companyName"
                      error={errors?.companyName?.message}
                      validation={{
                        required: "This is required.",
                      }}
                    />

                    <Input
                      label="Company Address"
                      dark={false}
                      register={register}
                      name="companyAddress"
                      error={errors?.companyAddress?.message}
                      validation={{
                        required: "This is required.",
                      }}
                    />
                    {!company?.companyLogo ? (
                      <Input
                        label="Company Logo"
                        helper="png, svg, jpg"
                        type="file"
                        dark={false}
                        register={register}
                        name="companyLogo[]"
                        error={errors?.companyLogo?.message}
                      />
                    ) : (
                      <>
                        <div className="row mb-3">
                          <div className="col-lg-4 col-4">
                            <h6>Company Logo</h6>
                            <img src={company?.companyLogo[0].url} alt={company.companyName}/>
                            <button
                              onClick={() => {
                                setNewLogo(!newLogo);
                              }}
                            >
                              {!newLogo ? "Replace" : "Leave as Is"}
                            </button>
                          </div>
                        </div>
                        {newLogo && (
                          <Input
                            label="New Logo"
                            helper="png, svg, jpg"
                            type="file"
                            dark={false}
                            register={register}
                            name="companyLogo[]"
                            error={errors?.companyLogo?.message}
                            validation={{
                              required: "This is required.",
                            }}
                          />
                        )}
                      </>
                    )}
                    <Input
                      label="Company Bio"
                      dark={false}
                      register={register}
                      name="companyBio"
                      placeholder="Sell in a little about your company. What's your USP etc."
                      error={errors?.companyBio?.message}
                      rows="10"
                      type="textarea"
                    />
                  </fieldset>

                  <fieldset className="my-5 pb-lg-5">
                    <div className="row">
                      <div className="col-lg-12">
                        <input
                          type="submit"
                          className="btn btn-block py-3 btn-secondary"
                          value="Update"
                        />
                        {accountUpdate && (
                          <p className="mt-4 bg-success px-2 pr-3 py-2 font-weight-bold text-darker-primary rounded d-inline-block small">
                            {" "}
                            <Icon
                              icon="check"
                              className="text-white ml-1 mr-1"
                            />{" "}
                            Your account has been updated
                          </p>
                        )}
                      </div>
                    </div>
                  </fieldset>

                  {!fistProfile && (
                    <fieldset className="mb-5">
                      <h2 className="h3 font-family-display">Your Password</h2>
                      <p>
                        To change your password, please logout, go back to login
                        and select password reset.
                        <br /> You'll be emailed a link to change your password.
                      </p>
                    </fieldset>
                  )}

                  {!fistProfile && (
                    <fieldset className="mb-5">
                      <h2 className="h3 font-family-display mb-4">
                        Delete Your Account
                      </h2>
                      {!requestDeletion ? (
                        <button
                          className="btn btn-sm btn-outline-danger"
                          onClick={handleDeletion}
                        >
                          Request Account Deletion
                        </button>
                      ) : (
                        <p>Account, deletion requested.</p>
                      )}
                    </fieldset>
                  )}
                </form>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}
export default Form;
