import React from "react";
import site from "./../../_data/marketTheme.json";
import { Link } from "react-router-dom";
import Icon from "../General/Icon";
import Skeleton from "react-loading-skeleton";

function OpportunityCard({
  id,
  meta,
  applyingCompanies,
  Photo,
  isLoading,
  name,
  Name,
  ShortDesc,
  companyName,
  featured = false,
  nolarge,
}) {
  return (
    <div
      className={`col-12 ${
        featured === true && !nolarge ? "col-lg-6" : "col-lg-3"
      } mb-4`}
    >
      <div
        className={`card overflow-hidden mb-4 h-100 ${
          site.OpportunitiesCardsBG
        } position-relative ${
          meta?.company && applyingCompanies && "opacity-60"
        }`}
      >
        {meta?.company && applyingCompanies && (
          <div className="card-tag rounded-lg shadow-lg bg-secondary d-inline text-center small px-3 py-2 font-weight-bold">
            <Icon icon="check" /> {site.OpportunitiesApplied}
          </div>
        )}
        <div className="embed-responsive embed-responsive-21by9">
          {Photo ? (
            <img
              src={Photo[0].thumbnails.large.url}
              alt={name}
              className="card-img-top"
              style={{
                height: "100%",
                position: "absolute",
                top: 0,
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          ) : isLoading ? (
            <Skeleton
              style={{
                height: "100%",
                position: "absolute",
                top: 0,
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          ) : (
            <div
              className="card-img-top bg-primary-grad-dark h-100"
              style={{
                height: "100%",
                position: "absolute",
                top: 0,
              }}
            />
          )}
        </div>
        <div className="card-body">
          <Link
            to={`/marketplace/opportunities/${id}`}
            className="stretched-link"
          >
            <h3 className="h5 card-title font-weight-bold">{Name}</h3>
            <p className="font-weight-normal card-text ">{ShortDesc}</p>
            <p className="small  mt-2 text-right">
              {isLoading ? (
                <Skeleton />
              ) : (
                <>
                  <span className="">{site.OpportunitiesAdvertisedBy}</span>{" "}
                  {companyName}
                </>
              )}
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default OpportunityCard;
