import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet-async";
import site from "../_data/marketTheme.json";
import ReactMarkdown from "react-markdown";

import OpportunityDetails from "../Components/Marketplace/Details";
import OpportunityDetailsLoading from "../Components/Marketplace/DetailsLoading";
import OpportunityApplyButton from "../Components/Marketplace/ApplyButton";
import BreadCrumb from "../Components/Navigation/BreadCrumb";

import { getOpportunity } from "../Components/API/API";
import Skeleton from "react-loading-skeleton";

// import Loading from "../Components/General/Loading";
import Error from "../Components/General/Error";

// TODO: Add new fields to layout

function Opportunity({
  user,
  accessToken,
  meta,
  isAuthenticated,
  company,
  companyId,
}) {
  const [opportunity, setOpportunity] = useState(false);
  const { loginWithRedirect } = useAuth0();

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isError, setIsError] = useState(false);

  const params = useParams();
  const opportunityId = params.opportunity;

  useEffect(() => {
    if (!opportunity && opportunityId && accessToken && companyId?.length) {
      getOpportunity(opportunityId, user?.sub, accessToken, companyId)
        .then((res) => {
          setOpportunity(res);
          setIsLoadingData(false);
        })
        .catch((error) => {
          setIsError(error);
          setIsLoadingData(false);
        });
    }
  }, [user, opportunity, opportunityId, accessToken, companyId]);

  return (
    <>
      <Helmet>
        {opportunity ? (
          <title>
            {opportunity.fields.Name} / {site.OpportunitiesName} /{" "}
            {site.BrandName}{" "}
          </title>
        ) : (
          <title>
            {site.OpportunitiesName} / {site.BrandName}
          </title>
        )}
      </Helmet>
      <section className="">
        {isLoadingData && (
          <>
            <div className="position-relative container-fluid pt-4 bg-light">
              <div className="row contained-xl justify-content-center align-self pt-lg-4">
                <div className="col-12 col-lg-7 mb-lg-5">
                  <div className="row journal">
                    <div className="col-12">
                      <h1 className="h2 font-family-display mb-2 mt-4">
                        <Skeleton className="pb-3" count={0.5} />
                      </h1>
                      <p className="mb-2">
                        <Skeleton className="pb-2" />
                      </p>
                    </div>
                    <div className="col-12 my-3">
                      <Skeleton
                        className="w-100 img-fluid rounded overflow-hidden opportunity-image"
                        style={{ minHeight: "25vw", height: "200px" }}
                      />
                    </div>
                    <div className="col-12 journal_content">
                      <div className="mb-2 oldcontent">
                        <Skeleton count={4.5} className="mb-3" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-3 mt-4 mb-5 text-right">
                  <Skeleton
                    className=" mb-4"
                    style={{ height: "150px" }}
                    count={0.75}
                  />
                  <Skeleton className="mb-4" count={0.5} />
                  <div className="p-4 bg-white mb-4">
                    <Skeleton count={3.5} className="mb-4" />
                  </div>
                  <div className="p-4 bg-white mb-4">
                    <Skeleton count={3.5} className="mb-4" />
                  </div>
                  <Skeleton className=" col-12" style={{ height: "75px" }} />
                </div>
              </div>
              {/* <div className="applybutton d-lg-none">
                <OpportunityApplyButton
                  opportunity={opportunity}
                  meta={meta}
                  isAuthenticated={isAuthenticated}
                />
              </div> */}
            </div>
          </>
        )}
        {isError && <Error />}
        {opportunity && (
          <>
            <BreadCrumb
              crumbs={[
                { title: "Browsing:", url: "" },
                {
                  title: site.OpportunitiesName,
                  url: "/marketplace/opportunities/",
                },
                {
                  title: opportunity.fields.Name,
                  url: "/marketplace/opportunities/" + opportunity?.id,
                },
              ]}
            />

            <div className="position-relative container-fluid pt-4 bg-light">
              <div className="row contained-xl justify-content-center align-self pt-lg-4">
                <div className="col-12 col-lg-7 mb-lg-5">
                  <div className="row journal">
                    <div className="col-12">
                      <h1 className="h2 font-family-display mb-2">
                        {opportunity.fields.Name}
                      </h1>
                      <p className="mb-2">{opportunity.fields.ShortDesc}</p>
                    </div>
                    {opportunity.fields.Photo && (
                      <div className="col-12 my-3">
                        <img
                          src={opportunity.fields.Photo[0].thumbnails.large.url}
                          alt=""
                          className="w-100 img-fluid rounded-sm shadow opportunity-image"
                        />
                      </div>
                    )}
                    <div className="col-12 journal_content">
                      <div className="mb-2 oldcontent">
                        <ReactMarkdown>
                          {opportunity.fields.Desc.replace(
                            /(?:\r\n|\r|\n)/g,
                            "\n\n"
                          )}
                        </ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-3  pl-lg-5 mt-lg-5 mb-5">
                  {!isAuthenticated && (
                    <div className="blurred-account p-4 bg-primary shadow rounded-lg text-center">
                      <h2 className="h5 mb-4">
                        You need a {site.BrandName} account to view more and
                        apply:
                      </h2>
                      <button
                        className={`btn btn-secondary px-3 p-2 mb-2`}
                        onClick={() =>
                          loginWithRedirect({
                            screen_hint: "signup",
                          })
                        }
                      >
                        {site.signup}
                      </button>
                      <button
                        className={`btn btn-outline-secondary px-3 p-2 ml-3 mb-2`}
                        onClick={() => loginWithRedirect()}
                      >
                        {site.login}
                      </button>
                    </div>
                  )}
                  <div className={`small text-left text-lg-right`}>
                    {isAuthenticated ? (
                      <OpportunityDetails
                        terms={true}
                        opportunity={opportunity}
                        isAuthenticated={isAuthenticated}
                      />
                    ) : (
                      <OpportunityDetailsLoading />
                    )}
                    <div className="row">
                      <div className="col-12 p-0 applybutton d-none d-lg-block">
                        <OpportunityApplyButton
                          opportunity={opportunity}
                          meta={meta}
                          isAuthenticated={isAuthenticated}
                          ViewerDDValid={company.DDValid}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="applybutton d-lg-none">
                <OpportunityApplyButton
                  opportunity={opportunity}
                  meta={meta}
                  isAuthenticated={isAuthenticated}
                  ViewerDDValid={company.DDValid}
                />
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
}
export default Opportunity;
