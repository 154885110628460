import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Icon from "./../Components/General/Icon";
import Loading from "./../Components/General/Loading";
import Error from "./../Components/General/Error";
import { getAPI } from "../Components/API/API";

import site from "../_data/marketTheme.json";
import CopyButton from "../Components/General/CopyButton";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  prism,
  nightOwl,
} from "react-syntax-highlighter/dist/esm/styles/prism";

function APIDoc({ section = "submit" }) {
  const params = useParams();
  const id = params.id ? params.id : "9QgnBA5pVR8qaYd6XCV4elPGvNJE71Lm";

  const [api, setAPI] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const themeOptions = {
    dark: {
      SyntaxHighlighter: nightOwl,
      background: "dark-primary",
      backgroundAlt: "primary",
      contrast: "light",
    },
    light: {
      SyntaxHighlighter: prism,
      background: "white",
      backgroundAlt: "light",
      contrast: "primary",
    },
  };
  const [currentTheme, setCurrentTheme] = useState("dark");
  const [theme, setTheme] = useState(themeOptions[currentTheme]);
  const currentDate = new Date();
  const currentDateISO = currentDate.toISOString();

  function swapTheme(switchTo) {
    setCurrentTheme(switchTo);
    setTheme(themeOptions[switchTo]);
  }

  const downloadCSVFile = (filename, content) => {
    const element = document.createElement("a");
    const file = new Blob([content], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  useEffect(() => {
    if (id && !api) {
      getAPI(id)
        .then((data) => {
          setIsLoading(false);
          let campaignParams = "";
          data.parameters.map(function (param, i) {
            if (param.name === "ssid") {
              return false;
            }
            return (campaignParams =
              campaignParams +
              ` \\` +
              `\n` +
              `-d '${param.name}=${
                param.name === "cid"
                  ? data.campaign.id
                  : param.name === "sid"
                  ? data.company.id
                  : param.name === "ssid"
                  ? "false"
                  : param.name === "optin_email"
                  ? true
                  : param.name === "optin_phone"
                  ? true
                  : param.name === "optin_sms"
                  ? true
                  : param.name === "optin_postal"
                  ? true
                  : param.name === "optin_email_timestamp"
                  ? currentDateISO
                  : param.name === "optin_phone_timestamp"
                  ? currentDateISO
                  : param.name === "optin_sms_timestamp"
                  ? currentDateISO
                  : param.name === "optin_postal_timestamp"
                  ? currentDateISO
                  : param.example_value
                  ? param.example_value
                  : param.name
              }'`);
          });

          data.successResponse = `{\n\t"result": "created",\n\t"lead_id": 123,\n}`;

          data.errorResponse =
            `{` +
            `\n\t"result": "error",` +
            `\n\t"lead_id": 123, ` +
            `\n\t"error": {` +
            `\n\t\t"msg": "INVALID_FORMAT",` +
            `\n\t\t"fields": [` +
            `\n\t\t\t"f_13_phone2",` +
            `\n\t\t\t"f_5_dob",` +
            `\n\t\t\t"f_12_phone1",` +
            `\n\t\t\t"f_14_phone3"` +
            `\n\t\t]` +
            `\n\t}` +
            `\n}`;

          data.errorResponseLeadUpdated = `{\n\t"result":"error",\n\t"error":{\n\t\t"msg":"invalid_key"\n\t},\n\t"message":"Page not found."\n}`;

          data.codeExample =
            `curl -L 'https://${data.lead_updates.host}/api/v1/lead' \\` +
            `\n-H 'Host: ${data.lead_updates.host}' \\` +
            `\n-H 'Content-Type: application/x-www-form-urlencoded'` +
            campaignParams;

          data.codeExampleUpdate =
            `curl -L 'https://${data.lead_updates.host}/api/v1/lead-data/update/[lead_id]' \\` +
            `\n-H 'Host: ${data.lead_updates.host}' \\` +
            `\n-H 'Content-Type: application/x-www-form-urlencoded' \\` +
            `\n-d 'key=XXXXXXXXX' \\` +
            `\n-d 'reprocess=false' \\` +
            `\n-d 'validate=false' \\` +
            params;

          data.FTPCSV = "";
          var sortedParams = structuredClone(data.parameters);
          sortedParams.sort((a, b) => a.name.localeCompare(b.name));

          if (sortedParams) {
            var passedfirst = false;
            var i = 0;
            for (i = 0; i < sortedParams.length; i++) {
              if (
                sortedParams[i].name !== "cid" &&
                sortedParams[i].name !== "sid" &&
                sortedParams[i].name !== "ssid"
              ) {
                if (passedfirst) {
                  data.FTPCSV = data.FTPCSV + ", ";
                } else {
                  passedfirst = true;
                }
                data.FTPCSV =
                  data.FTPCSV + sortedParams[i].name.replace(/f_[0-9]*_/, "");
              }
            }
            data.FTPCSV = data.FTPCSV + "\n";
            passedfirst = false;
            for (i = 0; i < sortedParams.length; i++) {
              if (
                sortedParams[i].name !== "cid" &&
                sortedParams[i].name !== "sid" &&
                sortedParams[i].name !== "ssid"
              ) {
                if (passedfirst) {
                  data.FTPCSV = data.FTPCSV + ", ";
                } else {
                  passedfirst = true;
                }
                data.FTPCSV =
                  data.FTPCSV +
                  (sortedParams[i].name === "optin_email"
                    ? true
                    : sortedParams[i].name === "optin_phone"
                    ? true
                    : sortedParams[i].name === "optin_sms"
                    ? true
                    : sortedParams[i].name === "optin_postal"
                    ? true
                    : sortedParams[i].name === "optin_email_timestamp"
                    ? currentDateISO
                    : sortedParams[i].name === "optin_phone_timestamp"
                    ? currentDateISO
                    : sortedParams[i].name === "optin_sms_timestamp"
                    ? currentDateISO
                    : sortedParams[i].name === "optin_postal_timestamp"
                    ? currentDateISO
                    : sortedParams[i].example_value
                    ? sortedParams[i].example_value
                    : sortedParams[i].name);
              }
            }
          }
          setAPI(data);
        })
        .catch((error) => {
          console.log(error);
          setIsError(true);
        });
    }
  }, [id, api, setAPI, currentDateISO, theme, params]);

  return (
    <>
      <Helmet>
        <title>APIDoc / {site.BrandName}</title>
      </Helmet>
      {/* {JSON.stringify(api)} */}
      {id && (
        <>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {!isError ? (
                <>
                  <div
                    className={`container-fluid vh-100 overflow-auto position-lg-fixed py-5 pt-lg-0 bg-${theme.background}`}
                  >
                    <div className="row">
                      <div
                        id="api_sidenav"
                        className="col-lg-2 vh-lg-100 position-lg-fixed px-xxl-5 pb-5 pb-lg-0"
                        style={{ left: 0, height: "100vh!important" }}
                      >
                        <div
                          className={`h-100 overflow-auto border-right border-${theme.contrast} pt-lg-5`}
                        >
                          <div className="d-block clearfix">
                            <h4 className="mb-4 float-left">API Docs</h4>
                            <button
                              className={`float-left mt-1 bg-transparent opacity-60 small border-0 p-0 text-${theme.contrast}`}
                              onClick={() => {
                                swapTheme(
                                  currentTheme === "light" ? "dark" : "light"
                                );
                              }}
                            >
                              <Icon
                                icon={currentTheme !== "dark" ? "moon" : "sun"}
                                className=" ml-2 mr-1"
                              />
                              <span className="">
                                {currentTheme === "dark" ? "light" : "dark"}{" "}
                                mode
                              </span>
                            </button>
                          </div>
                          <ul className="list-unstyled ps-0">
                            <li className="mb-2">
                              <Link
                                to={`/api/docs/${id}/submit`}
                                className="h5"
                              >
                                <Icon
                                  icon="user"
                                  className="text-secondary mr-2"
                                />
                                Leads
                              </Link>
                              <ul className="list-unstyled ml-0 mt-2">
                                <li className="mb-2">
                                  <Link to={`/api/docs/${id}/submit`}>
                                    <Icon
                                      icon="chevron-right"
                                      className={`text-${theme.contrast} small opacity-40 mr-3`}
                                    />
                                    Submitting
                                  </Link>
                                  <ul className="list-unstyled ml-3 mt-2">
                                    <li className="mb-2">
                                      <Link to={`/api/docs/${id}/submit`}>
                                        <Icon
                                          icon="chevron-right"
                                          className={`text-${theme.contrast} small opacity-40 mr-3`}
                                        />
                                        API/HTTP POST
                                      </Link>
                                    </li>
                                    <li className="mb-2">
                                      <Link to={`/api/docs/${id}/ftp`}>
                                        <Icon
                                          icon="chevron-right"
                                          className={`text-${theme.contrast} small opacity-40 mr-3`}
                                        />
                                        FTP
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li className="mb-2">
                                  <Link to={`/api/docs/${id}/update`}>
                                    <Icon
                                      icon="chevron-right"
                                      className={`text-${theme.contrast} small opacity-40 mr-3`}
                                    />
                                    Updating
                                  </Link>
                                </li>
                                {/* <li className="mb-2">
                                  <Link to={`/api/docs/${id}/webhooks`}>
                                    <Icon
                                      icon="chevron-right"
                                      className={`text-${theme.contrast} small opacity-40 mr-3`}
                                    />
                                    Webhooks
                                  </Link>
                                </li> */}
                              </ul>
                            </li>
                            <li className="mt-4">
                              <Link
                                to={`/api/docs/${id}/statistics`}
                                className="h5"
                              >
                                <Icon
                                  icon="bar-chart"
                                  className="text-secondary mr-2"
                                />
                                Statistics
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {section === "submit" && (
                        <div className="col-lg-10 pb-5 pt-lg-5 offset-lg-2">
                          <h1 className="h2">
                            <span className="text-secondary font-weight-normal font-family-display ">
                              Submitting Leads
                            </span>
                            <span className="h4 mt-0 d-block">
                              Campaign: {api.campaign.name}
                            </span>
                          </h1>

                          <section className="mt-5" id="api">
                            <h2 className="h3 mb-4">API / HTTP POST</h2>
                            <p className="small">
                              All data must use UTF-8 encoding - Leads may be
                              rejected or ignored if UTF-8 is not used.
                              <br />
                              Please ensure the data is uses POST from a server
                              side, client side is not available (if you need
                              this create a script on a server to parse it)
                            </p>
                            <div className="row">
                              <div className="col-lg-7">
                                <div className="mb-5">
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      URL
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      https://{api.lead_updates.host}
                                      /api/v1/lead
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      Method
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      POST
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      Content-Type
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      application/x-www-form-urlencoded
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <div>
                                      <span className="font-weight-bold mr-3">
                                        Host
                                      </span>
                                      <code
                                        className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                      >
                                        {api.lead_updates.host}
                                      </code>
                                    </div>
                                  </div>
                                </div>
                                <table
                                  className={`mt-4 table table-hover ${
                                    currentTheme ? "table-dark" : ""
                                  } bg-${theme.backgroundAlt} text-${
                                    theme.contrast
                                  }`}
                                >
                                  <thead>
                                    <tr
                                      className={`small bg-${theme.background}`}
                                    >
                                      <th className="">Param</th>
                                      <th className="">Format</th>
                                      <th className="">Example</th>
                                      <th className="">Required</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {api.parameters.map((param, i) => (
                                      <tr className="small" key={`param-${i}`}>
                                        <td className="font-weight-bold">
                                          {param.name}
                                        </td>
                                        <td className="">
                                          {param.name === "ssid"
                                            ? "1"
                                            : param.value
                                            ? param.value
                                            : param.format}
                                        </td>
                                        <td className="">
                                          {param.name === "cid"
                                            ? api.campaign.id
                                            : param.name === "sid"
                                            ? api.company.id
                                            : param.name === "ssid"
                                            ? "[See Below for List]"
                                            : param.name === "optin_email"
                                            ? "true"
                                            : param.name === "optin_phone"
                                            ? "true"
                                            : param.name === "optin_sms"
                                            ? "true"
                                            : param.name === "optin_postal"
                                            ? "true"
                                            : param.name ===
                                              "optin_email_timestamp"
                                            ? currentDateISO
                                            : param.name ===
                                              "optin_phone_timestamp"
                                            ? currentDateISO
                                            : param.name ===
                                              "optin_sms_timestamp"
                                            ? currentDateISO
                                            : param.name ===
                                              "optin_postal_timestamp"
                                            ? currentDateISO
                                            : param.example_value}
                                        </td>
                                        <td className="text-center">
                                          {param.required ? (
                                            <Icon
                                              icon="circle-check"
                                              className="text-danger"
                                            />
                                          ) : (
                                            <Icon
                                              icon="times"
                                              className={`text-${theme.contrast} opacity-40`}
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>

                              <div className="px-lg-5 col-lg-5">
                                <h3 className="h5">
                                  Curl Example{" "}
                                  <CopyButton
                                    className={`bg-transparent border-0 p-0 ml-1 text-${theme.contrast} opacity-60`}
                                    copy={api.codeExample}
                                  />
                                </h3>
                                <div className="small">
                                  <SyntaxHighlighter
                                    className={`syntax-highlighter bg-${theme.backgroundAlt}`}
                                    language="shell"
                                    showLineNumbers={true}
                                    style={theme.SyntaxHighlighter}
                                  >
                                    {api.codeExample}
                                  </SyntaxHighlighter>
                                </div>
                                <h3 className="h5 mt-5">
                                  Example Success Response{" "}
                                  <CopyButton
                                    className={`bg-transparent border-0 p-0 ml-1 text-${theme.contrast} opacity-60`}
                                    copy={api.successResponse}
                                  />
                                </h3>
                                <p className="small">
                                  <span className="font-weight-bold">
                                    Please Note:
                                  </span>{" "}
                                  a created response does not mean the lead is
                                  accepted, it means it's in the system for
                                  processing. We use webhooks to inform lead
                                  generators of the outcomes.{" "}
                                </p>
                                <div className="small">
                                  <SyntaxHighlighter
                                    className={`syntax-highlighter bg-${theme.backgroundAlt}`}
                                    language="json"
                                    showLineNumbers={true}
                                    style={theme.SyntaxHighlighter}
                                  >
                                    {api.successResponse}
                                  </SyntaxHighlighter>
                                </div>
                                <h3 className="h5 mt-5">
                                  Example Error Response{" "}
                                  <CopyButton
                                    className={`bg-transparent border-0 p-0 ml-1 text-${theme.contrast} opacity-60`}
                                    copy={api.errorResponse}
                                  />
                                </h3>
                                <p className="small">
                                  <span className="font-weight-bold">
                                    Please Note:
                                  </span>{" "}
                                  a lead ID may be supplied which can be used to
                                  check the lead for further messages or trigger
                                  an update if required.
                                </p>
                                <div className="small">
                                  <SyntaxHighlighter
                                    className={`syntax-highlighter bg-${theme.backgroundAlt}`}
                                    language="json"
                                    showLineNumbers={true}
                                    style={theme.SyntaxHighlighter}
                                  >
                                    {api.errorResponse}
                                  </SyntaxHighlighter>
                                </div>
                              </div>
                            </div>

                            <div className="mt-5">
                              <h3 className="h4">Handling Errors</h3>
                              <p>
                                If the lead has a result of "error" then error
                                message will be one of the following:
                              </p>
                              <table
                                className={`mt-4 table ${
                                  currentTheme ? "table-dark" : ""
                                } table-hover bg-${theme.backgroundAlt} text-${
                                  theme.contrast
                                }`}
                              >
                                <thead>
                                  <tr
                                    className={`small bg-${theme.background}`}
                                  >
                                    <th className="">Error</th>
                                    <th className="">Reason</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="small">
                                    <td className="">INVALID_FORMAT</td>
                                    <td className="">
                                      The lead submitted contains data in an
                                      invalid format. Please check the
                                      referenced field against the instruction
                                      in this documentation.
                                    </td>
                                  </tr>

                                  {Object.keys(api.http.errors).map((error) => (
                                    <tr
                                      className="small"
                                      key={`param-${error}`}
                                    >
                                      <td className="">{error}</td>
                                      <td className="">
                                        {api.http.errors[error]}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </section>
                        </div>
                      )}
                      {section === "ftp" && (
                        <div className="col-lg-10 pb-5 pt-lg-5 offset-lg-2">
                          <h1 className="h2">
                            <span className="text-secondary font-weight-normal font-family-display ">
                              Submitting Leads
                            </span>
                            <span className="h4 mt-0 d-block">
                              Campaign: {api.campaign.name}
                            </span>
                          </h1>

                          <section className="mt-5" id="ftp">
                            <div className="row">
                              <div className="col-lg-7">
                                <h2 className="h3 mb-4">FTP</h2>
                                <p className="">
                                  It is also possible to submit leads via FTP in
                                  CSV format. The FTP server is running on port
                                  21 on ftp.databowl.com. In order for the
                                  submission of leads to be successful there are
                                  a number of important conventions that must be
                                  followed.
                                </p>
                                <div className="mb-5">
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      URL
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      ftp.databowl.com
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      PORT
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      21
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      TYPE
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      FTP/FTPS
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      Credentiials
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      Your Databowl Login/Password
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      Column Delimiter
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      ,
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      Encoding
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      UTF-8
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      Filename
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      {api.instance}_{api.campaign.id}_
                                      {api.company.id}_yourreference.csv
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      Directory
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      /lead_uploads/
                                    </code>
                                  </div>
                                </div>

                                <h4>Common Issues</h4>
                                <ul>
                                  <li>
                                    If your FTP client reports: 226 Transfer
                                    done (but failed to open directory) you can
                                    safetly ignore this.
                                  </li>
                                  <li>
                                    You will not be able to see the file you
                                    have uploaded in the directory listing, this
                                    is intended. If the file transfer completed
                                    successfully, the file was named correctly
                                    and was in the correct format it will be
                                    processed. Do not repeatedly upload the same
                                    file.
                                  </li>
                                  <li>
                                    If a file that is in anyway invalid is
                                    uploaded the whole the file, or any part
                                    thereof, may be rejected without notice.
                                  </li>
                                  <li>
                                    Files that do not comply with the naming
                                    convention or include invalid values will be
                                    rejected with no notification or warning.
                                  </li>
                                  <li>
                                    Fields must be separated by a single comma
                                    delimiter.
                                  </li>
                                  <li>
                                    Fields may be enclosed in a pair of double
                                    quotation marks ("), if required (e.g if the
                                    field contains a comma).
                                  </li>
                                  <li>
                                    If it is required to escape a character (e.g
                                    if a quoted field contains a double
                                    quotation mark) then the character to be
                                    escaped must be prefixed with a single
                                    backslash.
                                  </li>
                                  <li>
                                    All rows must have the same number of
                                    fields.
                                  </li>
                                  <li>
                                    The first line of the file must contain a
                                    valid header (more details below)
                                  </li>
                                  <li>The file must be encoded in UTF-8</li>
                                </ul>
                              </div>
                              <div className="col-lg-5 px-lg-5">
                                <h3 className="h5 mt-5">
                                  Example File{" "}
                                  <CopyButton
                                    className={`bg-transparent border-0 p-0 ml-1 text-${theme.contrast} opacity-60`}
                                    copy={api.FTPCSV}
                                  />
                                </h3>
                                <p className="small">
                                  <span className="font-weight-bold">
                                    Please Note:
                                  </span>{" "}
                                  This file is supplied as an example, please
                                  ensure your data is adjusted as required.
                                </p>
                                <div className="small">
                                  <SyntaxHighlighter
                                    className={`syntax-highlighter bg-${theme.backgroundAlt}`}
                                    language="csv"
                                    showLineNumbers={true}
                                    style={theme.SyntaxHighlighter}
                                  >
                                    {api.FTPCSV}
                                  </SyntaxHighlighter>
                                </div>
                                <button
                                  className="btn btn-secondary btn-sm"
                                  onClick={() => {
                                    downloadCSVFile(
                                      `${api.instance}_${api.campaign.id}_${api.company.id}_yourreference.csv`,
                                      api.FTPCSV
                                    );
                                  }}
                                  // href={`https://${api.lead_updates.host}/api/rpc/public-source-integration/csv/${id}`}
                                >
                                  Download Example CSV <Icon icon="download" />
                                </button>
                              </div>
                            </div>
                          </section>
                        </div>
                      )}
                      {section === "update" && (
                        <div className="col-lg-10 pt-lg-5 offset-lg-2">
                          <h1 className="h2">
                            <span className="text-secondary font-weight-normal font-family-display ">
                              Updating Leads
                            </span>
                            <span className="h4 mt-0 d-block">
                              Campaign: {api.campaign.name}
                            </span>
                          </h1>

                          <section className="mt-5">
                            <div className="row">
                              <div className="col-lg-7">
                                <p>
                                  You can update lead data, optionally
                                  reprocessing and/or validating, by making a
                                  HTTP POST request to the following API:
                                </p>
                                <div className="mb-5">
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      URL
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      https://{api.lead_updates.host}
                                      /api/v1/lead-data/update/[lead_id]
                                    </code>
                                    <div className="d-block text-white small">
                                      NB: Replace [Lead_ID] with the ID of the
                                      lead to update
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      Method
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      POST
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <span className="font-weight-bold mr-3">
                                      Content-Type
                                    </span>
                                    <code
                                      className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                    >
                                      application/x-www-form-urlencoded
                                    </code>
                                  </div>
                                  <div className="mb-2">
                                    <div>
                                      <span className="font-weight-bold mr-3">
                                        Host
                                      </span>
                                      <code
                                        className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                      >
                                        {api.lead_updates.host}
                                      </code>
                                    </div>
                                  </div>
                                </div>

                                <table
                                  className={`mt-4 table table-hover ${
                                    currentTheme ? "table-dark" : ""
                                  } bg-${theme.backgroundAlt} text-${
                                    theme.contrast
                                  }`}
                                >
                                  <thead>
                                    <tr
                                      className={`small bg-${theme.background}`}
                                    >
                                      <th className="">Param</th>
                                      <th className="">Format</th>
                                      <th className="">Note</th>
                                      <th className="">Required</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="small">
                                      <td className="font-weight-bold">key</td>
                                      <td className="">XXXXXXXXXX</td>
                                      <td className="">
                                        You can find this either from the person
                                        managing the instance, or drop us a line
                                        and we'll get one for you.
                                      </td>
                                      <td className="">
                                        <Icon
                                          icon="circle-check"
                                          className="text-danger"
                                        />
                                      </td>
                                    </tr>
                                    <tr className="small">
                                      <td className="font-weight-bold">
                                        reprocess
                                      </td>
                                      <td className="">true / false</td>
                                      <td className="">
                                        Triggers the lead to be reprocessed
                                        following the update
                                      </td>
                                      <td className="">
                                        <Icon
                                          icon="circle-check"
                                          className="text-danger"
                                        />
                                      </td>
                                    </tr>
                                    <tr className="small">
                                      <td className="font-weight-bold">
                                        validate
                                      </td>
                                      <td className="">true / false</td>
                                      <td className="">
                                        This Indicates if the lead should be
                                        re-validated. Defaults to false.
                                      </td>
                                      <td className="">
                                        <Icon
                                          icon="circle-check"
                                          className="text-danger"
                                        />
                                      </td>
                                    </tr>
                                    <tr className="small">
                                      <td className="font-weight-bold">
                                        f_X_XXXXX
                                      </td>
                                      <td className="">value</td>
                                      <td className="">
                                        To update a field, please supply the f
                                        field name and set a value according to{" "}
                                        <Link
                                          to={`/api/docs/${id}/submit#api`}
                                          className="text-secondary"
                                        >
                                          our instructions
                                        </Link>
                                      </td>
                                      <td className="">
                                        <Icon
                                          icon="circle-check"
                                          className="text-danger"
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-5 px-lg-5">
                                <h3 className="h5">
                                  Curl Example{" "}
                                  <CopyButton
                                    className={`bg-transparent border-0 p-0 ml-1 text-${theme.contrast} opacity-60`}
                                    copy={api.codeExample}
                                  />
                                </h3>
                                <div className="small">
                                  <SyntaxHighlighter
                                    className={`syntax-highlighter bg-${theme.backgroundAlt}`}
                                    language="shell"
                                    showLineNumbers={true}
                                    style={theme.SyntaxHighlighter}
                                  >
                                    {api.codeExampleUpdate}
                                  </SyntaxHighlighter>
                                </div>

                                <h3 className="h5 mt-5">
                                  Error Response Example{" "}
                                  <CopyButton
                                    className={`bg-transparent border-0 p-0 ml-1 text-${theme.contrast} opacity-60`}
                                    copy={api.errorResponseLeadUpdated}
                                  />
                                </h3>
                                <div className="small">
                                  <SyntaxHighlighter
                                    className={`syntax-highlighter bg-${theme.backgroundAlt}`}
                                    language="json"
                                    showLineNumbers={true}
                                    style={theme.SyntaxHighlighter}
                                  >
                                    {api.errorResponseLeadUpdated}
                                  </SyntaxHighlighter>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      )}
                      {section === "webhooks" && (
                        <div className="col-lg-10 pt-lg-5 offset-lg-2">
                          <h1 className="h2">
                            <span className="text-secondary font-weight-normal font-family-display ">
                              Webhooks
                            </span>
                            <span className="h4 mt-0 d-block">
                              Campaign: {api.campaign.name}
                            </span>
                          </h1>

                          <section className="mt-5"></section>
                        </div>
                      )}
                      {section === "statistics" && (
                        <div className="col-lg-10 pt-lg-5 offset-lg-2">
                          <div className="row">
                            <div className="col-lg-7">
                              <h1 className="h2">
                                <span className="text-secondary font-weight-normal font-family-display ">
                                  Statistics
                                </span>
                                <span className="h4 mt-0 d-block">
                                  Campaign: {api.campaign.name}
                                </span>
                              </h1>

                              <p>
                                You can query our API to find out the progress
                                of your campaigns. The following options are
                                available
                              </p>

                              <div className="mt-5">
                                <h4>By Status Across All Campaigns</h4>
                                <p>
                                  You stats for all campaigns grouped by lead
                                  status
                                </p>
                                <div className="mb-3">
                                  <code
                                    className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                  >
                                    https://api.partnerhype.com/api/stats/status/ph/0/
                                    {api.company.id}
                                  </code>
                                </div>
                              </div>

                              <div className="mt-5">
                                <h4>By Status for a specific campaign</h4>
                                <p>
                                  You stats for this campaign grouped by lead
                                  status
                                </p>
                                <div className="mb-3">
                                  <code
                                    className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                  >
                                    https://api.partnerhype.com/api/stats/status/ph/
                                    {api.campaign.id}/{api.company.id}
                                  </code>
                                </div>
                              </div>
                              <div className="mt-5">
                                <h4>For Date Ranges</h4>

                                <p>
                                  With the above you can also pass a date from
                                  value as a timestamp
                                </p>
                                <div className="mb-3">
                                  <code
                                    className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                  >
                                    https://api.partnerhype.com/api/stats/status/ph/
                                    {api.campaign.id}/{api.company.id}
                                    /(dateFrom)
                                  </code>
                                </div>

                                <p>
                                  And you can also pass a date to as a value
                                  from a timestamp
                                </p>
                                <div className="mb-3">
                                  <code
                                    className={`bg-${theme.backgroundAlt} p-1 text-secondary`}
                                  >
                                    https://api.partnerhype.com/api/stats/status/ph/
                                    {api.campaign.id}/{api.company.id}
                                    /(dateFrom)/(dateTo)
                                  </code>
                                </div>
                                <div className="mt-5">
                                  <h3 className="h4">
                                    Lead Statuses Explained
                                  </h3>
                                  <table
                                    className={`mt-4 table table-hover ${
                                      currentTheme ? "table-dark" : ""
                                    } bg-${theme.backgroundAlt} text-${
                                      theme.contrast
                                    }`}
                                  >
                                    <thead>
                                      <tr
                                        className={`small bg-${theme.background}`}
                                      >
                                        <th className="">Status</th>
                                        <th className="">Description</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="small">
                                        <td className="font-weight-bold">
                                          Received
                                        </td>
                                        <td>
                                          Lead has been successfully received.
                                        </td>
                                      </tr>
                                      <tr className="small">
                                        <td className="font-weight-bold">
                                          Rejected
                                        </td>
                                        <td>
                                          Lead has been rejected by campaign
                                          configuration. This can include:
                                          duplicate leads, validation errors,
                                          suppression files, rule groups and
                                          more.
                                        </td>
                                      </tr>
                                      <tr className="small">
                                        <td className="font-weight-bold">
                                          Flagged
                                        </td>
                                        <td>
                                          The lead has been temporarily flagged.
                                          This will be reprocessed again in the
                                          near future. This can happen if there
                                          is an unexpected result from a
                                          validation API, for instance, a users
                                          mobile handset is currently
                                          disconnected, but may connect again in
                                          the near future.
                                        </td>
                                      </tr>
                                      <tr className="small">
                                        <td className="font-weight-bold">
                                          Pending
                                        </td>
                                        <td>
                                          All leads go into pending before
                                          processing. Leads will move out of
                                          pending once processed. This is a
                                          temporary state.
                                        </td>
                                      </tr>
                                      <tr className="small">
                                        <td className="font-weight-bold">
                                          Quarantine
                                        </td>
                                        <td>
                                          If a campaign has caps, leads
                                          delivered outside of this period may
                                          be quarantined. If your account is
                                          paused, leads may also be delivered to
                                          this status.
                                        </td>
                                      </tr>
                                      <tr className="small">
                                        <td className="font-weight-bold">
                                          Accepted
                                        </td>
                                        <td>
                                          The lead has been accepted onto a
                                          forwarding API.
                                        </td>
                                      </tr>
                                      <tr className="small">
                                        <td className="font-weight-bold">
                                          ClientRejected
                                        </td>
                                        <td>
                                          The lead has been rejected by the
                                          buyer, either manually,
                                          programatically via an outcome api, or
                                          via their API response.
                                        </td>
                                      </tr>
                                      <tr className="small">
                                        <td className="font-weight-bold">
                                          Sale
                                        </td>
                                        <td>
                                          The buyer has confirmed this lead has
                                          moved to a sale.
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 px-lg-5">
                              <h3 className="h5 mt-5">
                                Example Response{" "}
                                <CopyButton
                                  className={`bg-transparent border-0 p-0 ml-1 text-${theme.contrast} opacity-60`}
                                  copy={`{\n\t"filter": {\n\t\t"instance":"ph",\n\t\t"cid":null,\n\t\t"sid":null,\n\t\t"date":{\n\t\t\t"from":null,\n\t\t\t"to":null\n\t\t}\n\t},\n\t"totalLeads":0,\n\t"statsBy":{\n\t\t"status":{\n\t\t\t"Received":0,\n\t\t\t"Rejected":0,\n\t\t\t"Flagged":0,\n\t\t\t"Pending":0,\n\t\t\t"Quarantine":0,\n\t\t\t"Accepted":0,\n\t\t\t"clientRejected":0,\n\t\t\t"Sale":0\n\t\t}\n\t}\n}`}
                                />
                              </h3>
                              <div className="small">
                                <SyntaxHighlighter
                                  className={`syntax-highlighter bg-${theme.backgroundAlt}`}
                                  language="json"
                                  showLineNumbers={true}
                                  style={theme.SyntaxHighlighter}
                                >
                                  {`{\n\t"filter": {\n\t\t"instance":"ph",\n\t\t"cid":null,\n\t\t"sid":null,\n\t\t"date":{\n\t\t\t"from":null,\n\t\t\t"to":null\n\t\t}\n\t},\n\t"totalLeads":0,\n\t"statsBy":{\n\t\t"status":{\n\t\t\t"Received":0,\n\t\t\t"Rejected":0,\n\t\t\t"Flagged":0,\n\t\t\t"Pending":0,\n\t\t\t"Quarantine":0,\n\t\t\t"Accepted":0,\n\t\t\t"clientRejected":0,\n\t\t\t"Sale":0\n\t\t}\n\t}\n}`}
                                </SyntaxHighlighter>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <Error />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
export default APIDoc;
