import React, { useState, useEffect } from "react";
import site from "./../../_data/marketTheme.json";

import { getSearchData } from "../API/API";

function Search({
  setSearchDone,
  sector,
  setSector,
  setCountry,
  country,
  // setPaymentModel,
  // // paymentModel,
  // setCategory,
  // category,
  // setAudience,
  // audience,
  setCapabilities,
  capabilities,
  setApplied,
  applied,
}) {
  const [searchData, setSearchData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!searchData) {
      getSearchData()
        .then((res) => {
          console.log(res);
          setSearchData(res);
          setIsLoadingData(false);
        })
        .catch((error) => {
          setIsError(true);
        });
    }
  }, [isLoadingData, isError, searchData]);

  return (
    <div className="col-12 col-lg-2">
      <div className="bg-white p-4 mb-5">
        <h5>{site.Filters}</h5>

        {/* <div>
          <select
            className="form-control form-control-sm mb-2 px-3"
            onChange={(e) => {
              setSearchDone(false);
              setCategory(e.target.value);
            }}
            defaultValue="Category:"
          >
            <option disabled>Category:</option>
            <option value="">All</option>
            <option>Lead Generation</option>
            <option>Traffic</option>
            <option>Design</option>
            <option>Coding</option>
            <option>Other</option>
          </select>
        </div> */}

        {searchData?.sectors && (
          <div>
            <select
              className="form-control form-control-sm mb-2 px-3"
              onChange={(e) => {
                setSearchDone(false);
                setSector(e.target.value);
              }}
              defaultValue="Sector:"
            >
              <option disabled>Sector:</option>
              <option value="">Any</option>
              {Object.keys(searchData.sectors).map((sector, i) => (
                <option key={`sector-${i}`} value={sector}>
                  {sector} ({searchData.sectors[sector]})
                </option>
              ))}
            </select>
          </div>
        )}

        {/* <div>
          <select
            className="form-control form-control-sm mb-2 px-3"
            onChange={(e) => {
              setSearchDone(false);
              setAudience(e.target.value);
            }}
            defaultValue="Audience:"
          >
            <option disabled>Audience:</option>
            <option value="">Any</option>
            <option>18-21</option>
            <option>22-29</option>
            <option>30-35</option>
            <option>36-40</option>
            <option>41-50</option>
            <option>51-60</option>
            <option>61-70</option>
            <option>71-80</option>
            <option>80+</option>
          </select>
        </div> */}

        {searchData?.countries && (
          <div>
            <select
              placeholder="Please Select"
              className="form-control form-control-sm mb-2 px-3"
              onChange={(e) => {
                setSearchDone(false);
                setCountry(e.target.value);
              }}
              defaultValue="Campaign Country:"
            >
              <option disabled>Campaign Country:</option>
              <option value="">Any</option>
              {Object.keys(searchData.countries).map((country, i) => (
                <option key={`country-${i}`} value={country}>
                  {country} ({searchData.countries[country]})
                </option>
              ))}
            </select>
          </div>
        )}

        {searchData?.capabilities && (
          <div>
            <select
              placeholder="Please Select"
              className="form-control form-control-sm mb-2 px-3"
              onChange={(e) => {
                setSearchDone(false);
                setCapabilities(e.target.value);
              }}
              defaultValue="Capabilities:"
            >
              <option disabled>Capabilities:</option>
              <option value="">Any</option>

              {Object.keys(searchData.capabilities).map((capability, i) => (
                <option key={`capability-${i}`} value={capability}>
                  {capability} ({searchData.capabilities[capability]})
                </option>
              ))}
            </select>
          </div>
        )}

        {/* TODO: only show if campaigns are present */}
        <div>
          <select
            placeholder="Please Select"
            className="form-control form-control-sm mb-2 px-3"
            onChange={(e) => {
              setSearchDone(false);
              setApplied(e.target.value);
            }}
            defaultValue={`Show ${site.OpportunitiesApplied}:`}
          >
            <option disabled>Show {site.OpportunitiesApplied}:</option>
            <option value="">Show All</option>
            <option value="No">Hide All Applied</option>
            <option value="Yes">Show Only Applied</option>
          </select>
        </div>
      </div>
    </div>
  );
}
export default Search;
