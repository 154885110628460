import React, { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../General/Icon";
import Moment from "react-moment";

import site from "./../../_data/marketTheme.json";

function OpportunityDetails({ split = false, terms = false, opportunity }) {
  const [viewTerms, setViewTerms] = useState(false);
  return (
    <div className="row">
      <div className={`col-lg-${split ? 6 : 12}`}>
        {opportunity.fields.companyLogo && (
          <img
            src={opportunity.fields.companyLogo[0].url}
            alt={opportunity.fields.companyName}
            className="col-8 p-0 mb-4"
          />
        )}
        <h2 className="h4 mb-0">
          <span className="">{site.OpportunitiesAdvertisedBy}</span>{" "}
          {opportunity.fields.companyName}
        </h2>
      </div>
      <div className={`bg-white p-4  col-lg-${split ? 6 : 12} mt-4`}>
        <ul className="list-unstyled">
          <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">Campaign Dates</span>{" "}
            <div>
              <Icon icon="calendar" className="text-secondary mr-1" />{" "}
              <Moment format="DD/MM/YYYY">
                {opportunity.fields.StartDate}
              </Moment>
              {" - "}
              <Moment format="DD/MM/YYYY">{opportunity.fields.EndDate}</Moment>
            </div>
          </li>
          <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">Sector</span>{" "}
            <div>
              <Icon icon="folders" className="text-secondary mr-1" />{" "}
              {opportunity.fields.sector}
            </div>
          </li>
          {/* <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">Category</span>{" "}
            <div>
              <Icon icon="chart-line" className="text-secondary mr-1" />{" "}
              {opportunity.fields.Category}
            </div>
          </li> */}
          <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">Audience</span>{" "}
            <div>
              {opportunity.fields.Audience && (
                <span className="ml-2">
                  <Icon icon="user" className="text-secondary mr-1" />{" "}
                  {opportunity.fields.Audience}
                </span>
              )}
            </div>
          </li>
          <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">Country</span>{" "}
            <div>
              {opportunity.fields.country && (
                <span className="ml-2">
                  <Icon icon="earth" className="text-secondary mr-1" />{" "}
                  {opportunity.fields.country}
                </span>
              )}
            </div>
          </li>
        </ul>
      </div>

      <div className={`bg-white p-4  col-lg-${split ? 6 : 12} my-4`}>
        <ul className="list-unstyled">
          <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">Payment Model</span>{" "}
            <div>
              <Icon icon="coins" className="text-secondary mr-1" />{" "}
              {opportunity.fields.paymentModel} @ &pound;
              {opportunity.fields.opportunityIdealCPL} per lead
            </div>
          </li>

          {/* <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">ContactBuyingHistory</span>{" "}
            <div>
              <Icon icon="calendar" className="text-secondary mr-1" />{" "}
              {opportunity.fields.opportunityContactBuyingHistory}
            </div>
          </li> */}

          <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">Lead Limit</span>{" "}
            <div>
              <Icon icon="calendar" className="text-secondary mr-1" />{" "}
              {opportunity.fields.opportunityLimit} per{" "}
              {opportunity.fields.opportunityLimitPeriod}
            </div>
          </li>
          <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">Rejection Period</span>{" "}
            <div>
              <Icon icon="calendar" className="text-secondary mr-1" />{" "}
              {opportunity.fields.opportunityRejectionPeriod}
            </div>
          </li>
          <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">Delivery Mechanism</span>{" "}
            <div>
              <Icon icon="database" className="text-secondary mr-1" />{" "}
              {opportunity.fields.opportunityDeliveryMechanism}
            </div>
          </li>
          {opportunity.fields.opportunityDueDil && (
            <li className="pb-2 mb-1 border-bottom pt-1">
              <span className="font-weight-bold">Due Diligence Doc</span>{" "}
              <div>
                <Icon icon="check" className="text-secondary mr-1" /> Required
              </div>
            </li>
          )}
          <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">Payment Terms</span>{" "}
            <div>
              <Icon icon="calendar" className="text-secondary mr-1" />{" "}
              {opportunity.fields.opportunityPaymentTerms}
            </div>
          </li>

          <li className="pb-2 mb-1 border-bottom pt-1">
            <span className="font-weight-bold">Traffic Types</span>{" "}
            <span>
              {opportunity.fields.capabilities ? (
                <div>
                  {opportunity.fields.capabilities.map((capability, i) => (
                    <span className="ml-2" key={"capability-" + i}>
                      <Icon icon="desktop" className="text-secondary mr-1" />{" "}
                      {capability}
                    </span>
                  ))}
                </div>
              ) : (
                "N/A"
              )}
            </span>
          </li>
          {opportunity.fields.opportunityContentApproval && (
            <li className="pb-2 mb-1 border-bottom pt-1">
              <span className="font-weight-bold">
                Content Approval Required
              </span>{" "}
              <div>
                <Icon icon="lock" className="text-secondary mr-1" /> This
                opportunity requires that {opportunity.fields.companyName}{" "}
                approve any content, such as, but not limited to: adverts,
                content, emails and landing pages.
              </div>
              {opportunity.fields.opportunityContentApproval}
            </li>
          )}
          {terms && (
            <>
              <li className="pt-1">
                <Link
                  to="#terms"
                  onClick={(e) => setViewTerms(viewTerms ? false : true)}
                  className="font-weight-bold"
                >
                  Terms <Icon icon={!viewTerms ? "caret-down" : "caret-up"} />
                </Link>
                {viewTerms && (
                  <div
                    id="terms"
                    className="text-left hidden mt-2 bg-light p-4"
                    dangerouslySetInnerHTML={{
                      __html: opportunity.fields.terms.replace(
                        /(?:\r\n|\r|\n)/g,
                        "<br>"
                      ),
                    }}
                  />
                )}
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default OpportunityDetails;
