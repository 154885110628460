// import React from "react"; //, { useState, useEffect}
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "./Components/Layout";

import ProtectedRoute from "./Components/Authentication/ProtectedRoute";
import { GetUser } from "./Components/Authentication/GetUser.js";
import ScrollToTop from "./Components/ScrollToTop";

// const ViewCertificate = lazy(() => import("./Templates/ViewCertificate"));
// const DueDiligenceView = lazy(() => import("./Templates/DueDiligenceView"));
// const Opportunity = lazy(() => import("./Templates/Opportunity"));
// const OpportunityApply = lazy(() => import("./Templates/OpportunityApply"));
// const Home = lazy(() => import("./Pages/Home"));
// const LeadCertificates = lazy(() => import("./Pages/LeadCertificates"));
// const DueDiligence = lazy(() => import("./Pages/DueDiligence"));
// const DueDiligenceForm = lazy(() => import("./Pages/DueDiligenceForm"));
// const Terms = lazy(() => import("./Pages/Account/Terms"));
// const Account = lazy(() => import("./Pages/Account/Account"));
// const OpportunityCreate = lazy(() =>  import("./Pages/Marketplace/OpportunityCreate"));
// const InvitedCreate = lazy(() =>  import("./Pages/Invite/InvitedCreate"));
// const OpportunityDirectory = lazy(() =>  import("./Pages/Marketplace/Opportunities"));
// const YourApplicationsPage = lazy(() => import("./Pages/YourApplications"));
// const YourListings = lazy(() => import("./Pages/YourListings"));
// const YourApplicants = lazy(() => import("./Pages/YourApplicants"));
// const NotFound = lazy(() => import("./Pages/404"));
// const Dashboard = lazy(() => import("./Pages/Dashboard"));
// const PricingPage = lazy(() => import("./Pages/PricingPage"));

import ViewCertificate from "./Templates/ViewCertificate";
import DueDiligenceView from "./Templates/DueDiligenceView";
import Opportunity from "./Templates/Opportunity";
import OpportunityApply from "./Templates/OpportunityApply";
import APIDoc from "./Templates/APIDoc";

import Home from "./Pages/Home";
import LeadCertificates from "./Pages/LeadCertificates";
import DueDiligence from "./Pages/DueDiligence";
import DueDiligenceForm from "./Pages/DueDiligenceForm";
import Terms from "./Pages/Account/Terms";
import Account from "./Pages/Account/Account";
import OpportunityCreate from "./Pages/Marketplace/OpportunityCreate";
import InvitedCreate from "./Pages/Invite/InvitedCreate";
import OpportunityDirectory from "./Pages/Marketplace/Opportunities";
import YourApplicationsPage from "./Pages/YourApplications";
import YourListings from "./Pages/YourListings";
import YourApplicants from "./Pages/YourApplicants";
import YourListingTemplate from "./Templates/YourListingTemplate";

import NotFound from "./Pages/404";
import WelcomeInvite from "./Pages/Invite/WelcomeInvite";

import Dashboard from "./Pages/Dashboard";
import ContentApprovalPage from "./Pages/ContentApproval";
import ContentApprovalTemplate from "./Templates/ContentApprovalTemplate";

function App() {
  let user = GetUser();

  return (
    // <>{JSON.stringify(user)}< />
    <BrowserRouter>
      <Suspense>
        <ScrollToTop>
          <Layout user={user}>
            <Routes>
              {/* START ROUTES */}
                  {/* START ROUTES WHERE ACCOUNT NOT REQUIRED */}

                        {/* NO ACCOUNT REQUIRED INVITATION */}
                        <Route path="/invited/post/" element={<InvitedCreate {...user} />} />
                        {/* VIEW PRICING */}
                        {/* <Route path="/pricing/" element={<PricingPage  />}  /> */}
                        {/* SHOW DASHBOARD IF LOGGED IN, OR HOMEPAGE */}
                        <Route path="/" element={<>{user?.isAuthenticated ? <Dashboard {...user}  /> : <Home {...user}  />}</>} />
                        {/* CUSTOM INVITATION PAGE */}
                        <Route path="/invite" element={<WelcomeInvite  />}  />
                        {/* VIEW API DOCS */}
                        <Route path="/api/docs/:id/submit" element={<APIDoc {...user} section="submit" />} />
                        <Route path="/api/docs/:id/ftp" element={<APIDoc {...user} section="ftp" />} />
                        <Route path="/api/docs/:id/update" element={<APIDoc {...user} section="update" />} />
                        <Route path="/api/docs/:id/webhooks" element={<APIDoc {...user} section="webhooks" />} />
                        <Route path="/api/docs/:id/statistics" element={<APIDoc {...user} section="statistics" />} />
                        <Route path="/api/docs/:id/" element={<APIDoc {...user}  />}  />
                        {/* <Route path="/api/docs/" element={<APIDoc {...user}  />}  /> */}

                        {/* VIEW LEAD CERTIFICATE */}
                        <Route path="/lead-certificate/:leadId" element={<ViewCertificate />} />
                        {/* LEAD CERTIFICATES SEARCH PAGE */}
                        <Route path="/lead-certificate/" element={<LeadCertificates {...user} />} />
                        {/* DUE DILIGENCE CERTIFICATE */}
                        <Route path="/due-diligence/:cert" element={<DueDiligenceView />} />
                        {/* APPLY DUE DILIGENCE */}
                        <Route path="/due-diligence/apply/" element={<DueDiligenceForm {...user} />} />
                  
                  {/* END ROUTES WHERE ACCOUNT NOT REQUIRED */}



              {/* START ROUTES WHERE ACCOUNT IS REQUIRED */}

                  {/* START ROUTES WHERE TERMS NOT REQUIRED */}
                      <Route path="/account/terms/" element={  <ProtectedRoute {...user}><Terms {...user} /></ProtectedRoute>} />
                  {/* END ROUTES WHERE TERMS NOT REQUIRED */}

                  {/* START ROUTES WHERE TERMS REQUIRED */}
                      <Route path="/account/" element={<ProtectedRoute {...user}><Account {...user} /></ProtectedRoute>} />

                      {/* START ROUTES WHERE LEAD GENERATION ROLE REQUIRED */}
                          {/* LIST OPPORTUNITIES */}
                          <Route path="/marketplace/opportunities/" element={<ProtectedRoute role="generateLead" {...user}><OpportunityDirectory {...user} /></ProtectedRoute>} />
                          {/* VIEW OPPORTUNITY */}
                          <Route path="/marketplace/opportunities/:opportunity" element={ <ProtectedRoute {...user}><Opportunity {...user} /></ProtectedRoute>} />
                          {/* APPLY TO OPPORTUNIY */}
                          <Route path="/marketplace/opportunities/:opportunity/apply/" element={<ProtectedRoute {...user}><OpportunityApply {...user} /></ProtectedRoute>} />
                          {/* LIST OPPORTUNITY APLICATIONS */}
                          <Route path="/marketplace/applications/" element={<ProtectedRoute role="generateLead" {...user}><YourApplicationsPage {...user} /></ProtectedRoute>} />
                          {/* VIEW OPPORTUNITY APPLICATION */}
                          <Route path="/marketplace/applications/:appId" element={<ProtectedRoute role="generateLead" {...user}><YourApplicationsPage {...user} /></ProtectedRoute>} />
                          {/* LIST ALL DUE DILIGENCE */}
                          <Route path="/due-diligence/" element={<ProtectedRoute role="generateLead" {...user}><DueDiligence {...user} /></ProtectedRoute>} />
                          
                      {/* END ROUTES WHERE LEAD GENERATION ROLE REQUIRED */}

                      {/* START ROUTES WHERE ADVERTISER ROLE REQUIRED */}
                          {/* CREATE OPPORTUNITY */}
                          <Route path="/marketplace/opportunities/create" element={<ProtectedRoute role="advertiser" {...user}><OpportunityCreate {...user} /></ProtectedRoute>} />
                          {/* CREATE OPPORTUNITY */}
                          <Route path="/content-approval/" element={<ProtectedRoute role="advertiser" {...user}><ContentApprovalPage {...user} /></ProtectedRoute>} />
                          <Route path="/content-approval/:contentId" element={<ProtectedRoute role="advertiser" {...user}><ContentApprovalTemplate {...user} /></ProtectedRoute>} />
                          {/* LIST YOUR LISTINGS */}
                          <Route path="/marketplace/listings/" element={<ProtectedRoute role="advertiser" {...user}><YourListings {...user} /></ProtectedRoute>} />
                          {/* VIEW SPECIFIC LISTING DASHBOARD */}
                          <Route path="/marketplace/listings/:advertId" element={<ProtectedRoute role="advertiser" {...user}><YourListingTemplate {...user} /></ProtectedRoute>} />
                          {/* LIST APPLICANTS */}
                          <Route path="/marketplace/applicants/" element={<ProtectedRoute role="advertiser" {...user}><YourApplicants {...user} /></ProtectedRoute>} />
                          {/* VIEW APPLICATION */}
                          <Route path="/marketplace/applicants/:appId" element={<ProtectedRoute role="advertiser" {...user}><YourApplicants {...user} /></ProtectedRoute>} />
                      {/* END ROUTES WHERE ADVERTISER ROLE REQUIRED */}
                {/* END ROUTES WHERE TERMS REQUIRED */}

              {/* 404 WILDCARD ROUTE */}
                  <Route path="*" element={<NotFound  />}  />
              {/* END ROUTES */}
            </Routes>
          </Layout>
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
